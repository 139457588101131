import { useEffect, useMemo, useRef } from "react"
import create from "zustand"
import useEffectOnce from "react-use/lib/useEffectOnce"

import Sounds from "lib/Sounds/Sounds"

const DEFAULT_STATE = {
  enabled: true,
  perQuestion: 60,
  current: 60,
  is_running: false,
  ran_out: false,
}

export const useTimeState = create((set) => ({
  ...DEFAULT_STATE,
  resetState: (overrides = {}) => set({ ...DEFAULT_STATE, ...overrides }),

  start: () => set({ is_running: true }),
  pause: () => set({ is_running: false }),
  reset: () =>
    set((s) => ({
      current: s.perQuestion,
      ran_out: false,
    })),
  secondPassed: () =>
    set((s) => {
      const current = Math.max(s.current - 1, 0)
      const is_running = s.is_running && current > 0

      return {
        current,
        is_running,
      }
    }),
  timeRanOut: () => set({ ran_out: true }),
}))

export const useTime = (config) => {
  const {
    resetState,

    enabled,
    perQuestion,
    current,
    is_running,
    ran_out,

    start,
    pause,
    reset,
    secondPassed,
    timeRanOut,
  } = useTimeState()

  useEffectOnce(() => {
    const { enabled, perQuestion } = config.parameters.time
    resetState({ enabled: enabled && perQuestion > 0, current: perQuestion, perQuestion })

    return () => {
      clearInterval(time_running_interval_ref.current)
      resetState()
    }
  })

  const time_running_interval_ref = useRef(0)

  const is_running_out = perQuestion > 0 ? current < perQuestion / 4 : false
  const readable = useMemo(() => {
    const minutes = Math.floor(current / 60)
    const seconds = current % 60
    return `${minutes < 10 ? "0" : ""}${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }, [current])

  useEffect(() => {
    if (enabled) {
      if (is_running) {
        time_running_interval_ref.current = setInterval(secondPassed, 1000)
      } else {
        clearInterval(time_running_interval_ref.current)
      }
    }
  }, [secondPassed, enabled, is_running])

  useEffect(() => {
    if (enabled && current === 0 && perQuestion > 0) {
      Sounds.time_out.play()
      timeRanOut()
    }
  }, [enabled, current, perQuestion, timeRanOut])

  return {
    enabled,
    is_running,
    is_running_out,
    ran_out,

    start,
    pause,
    reset,

    readable,
  }
}
