import merge from "lodash/merge";
import getConfig from "../../@exercises/configs";

const T = {
  LOAD: "EXERCISE__LOAD",
};

const ExerciseActions = {
  T,
  S: {
    EXERCISE: (state) => state.exercise.data,
  },

  load: (data) => {
    const config = getConfig(data.slug);

    return {
      type: T.LOAD,
      payload: {
        data: merge(config.data, data),
      },
    };
  },
};

export default ExerciseActions;
