import { useCallback, useEffect, useRef, useState } from "react"
import head from "lodash/head"
import tail from "lodash/tail"

const EMPTY_CALLBACK = {
  timeout: undefined,
  func: undefined,
  delay: undefined,
}

const useCurrentState = (states, initial) => {
  const [state, setState] = useState(initial)
  const callbackRef = useRef({ ...EMPTY_CALLBACK })
  const stateSequenceRef = useRef({
    timeout: 0,
  })

  const changeState = useCallback((newState, callback, callbackDelay) => {
    clearTimeout(callbackRef.current.timeout)

    callbackRef.current = callback
      ? {
          ...callbackRef.current,
          func: callback,
          delay: callbackDelay ?? 0,
        }
      : { ...EMPTY_CALLBACK }

    setState(newState)
  }, [])

  const changeStatesInSequence = useCallback(
    (newStates, statesDelay, callback, callbackDelay) => {
      clearTimeout(stateSequenceRef.current.timeout)

      if (newStates.length === 1) {
        changeState(head(newStates), callback, callbackDelay)
      } else {
        changeState(head(newStates))
        stateSequenceRef.current.timeout = setTimeout(
          () => changeStatesInSequence(tail(newStates), statesDelay, callback, callbackDelay),
          statesDelay
        )
      }
    },
    [changeState]
  )

  useEffect(() => {
    if (callbackRef.current) {
      const { func, delay } = callbackRef.current
      callbackRef.current.timeout = setTimeout(func, delay)
    }
  }, [state])

  const inState = useCallback(
    (checkedState) => {
      if (Array.isArray(checkedState)) {
        return checkedState.includes(state)
      } else {
        return checkedState === state
      }
    },
    [state]
  )

  return {
    state,
    changeState,
    changeStatesInSequence,
    inState,
  }
}

export default useCurrentState
