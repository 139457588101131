import React, { Component } from "react"
import propTypes from "prop-types"
import clsx from "clsx"

import "./Card.scss"

export default class Card extends Component {
  static COLORS = {
    BRIGHT: "bright",
    MAIN: "main",
    ACCENT: "accent",
    SUCCESS: "success",
    FAILURE: "failure",
    CLICKABLE: "clickable",
  }

  static propTypes = {
    className: propTypes.string,
    color: propTypes.string,
    style: propTypes.object,

    isBlurred: propTypes.bool,
    isClickable: propTypes.bool,
    isOpaque: propTypes.bool,

    innerRef: propTypes.any,

    isDraggable: propTypes.bool,

    onClick: propTypes.func,
  }

  static defaultProps = {
    color: Card.COLORS.MAIN,

    onClick: () => {},
  }

  render() {
    const {
      className,
      color,
      isBlurred,
      isOpaque,
      isClickable,
      onClick,
      isDraggable,
      style,
      children,
      innerRef,
      ...otherProps
    } = this.props

    return (
      <div
        className={clsx("Card", `color-${color}`, className, {
          blurred: isBlurred,
          clickable: isClickable,
          draggable: isDraggable,
          opaque: isOpaque,
        })}
        style={style}
        onClick={onClick}
        ref={innerRef}
        {...otherProps}
      >
        {children}
      </div>
    )
  }
}

export const BorderedCard = ({ children, className, ...other }) => (
  <Card className={clsx("BorderedCard", className)} {...other}>
    {children}
  </Card>
)
