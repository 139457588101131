import React from "react"
import clsx from "clsx"
import useGameSize from "../../hooks/screen/useGameSize"
import { useSprintState } from "state/$sprint"

export interface SprintAreaProps {
  children: React.ReactNode

  slug: string
}

const SprintArea = ({ children, slug }: SprintAreaProps) => {
  const { width, widthEms, height, font, isVertical } = useGameSize()
  const {
    sides: { left, right, top, bottom },
  } = useSprintState()

  return (
    <div
      className={clsx("SprintArea", "scrollable", slug)}
      style={{
        fontSize: font,
        width: isVertical ? `${widthEms}rem` : width,
        height: height,
      }}
    >
      <div className={clsx("side left", { show: left })} />
      <div className={clsx("side right", { show: right })} />
      <div className={clsx("side top", { show: top })} />
      <div className={clsx("side bottom", { show: bottom })} />
      <div className="inner-sprint-area">{children}</div>
    </div>
  )
}

export default SprintArea
