import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"

import { isPointInRectangle } from "lib/Geometry"

import { ReactComponent as BoxTopImg } from "../img/box-top.svg"
import { ReactComponent as BoxBottomImg } from "../img/box-bottom.svg"
import "./Box.scss"

export default function Box({ isDragging, draggedPosition, onHit }) {
  const cauldronRef = useRef(null)
  const [hovering, setHovering] = useState(false)

  useEffect(() => {
    function wasDraggedToBox() {
      if (draggedPosition) {
        const cardRect = cauldronRef.current.getBoundingClientRect()
        return isPointInRectangle(draggedPosition, cardRect)
      }

      return false
    }

    if (wasDraggedToBox()) {
      if (isDragging) {
        setHovering(true)
      } else {
        setHovering(false)
        onHit()
      }
    } else {
      setHovering(false)
    }
  }, [isDragging, draggedPosition, onHit])

  return (
    <div ref={cauldronRef} className={clsx("Box", { hovering })}>
      <div className="box-container">
        <div className="box-svg">
          <BoxTopImg className="box-top" />
          <BoxBottomImg className="box-bottom" />
        </div>
      </div>
    </div>
  )
}
