import React from "react"
import ReactMarkdown from "react-markdown"
import clsx from "clsx"

const Markdown = ({ className, children }) => (
  <div className={clsx(className, "Markdown")}>
    <ReactMarkdown source={children} />
  </div>
)

export default Markdown
