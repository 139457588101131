import React from "react"
import { cloneDeep, defaults, defaultTo, shuffle } from "lodash"
import classNames from "classnames"

import StateComponent from "./StateComponent"
import ModuleFactory from "@exercises/modules/ModuleFactory"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import PointsBar from "components/PointsBar/PointsBar"
import Container from "components/Container/Container"
import Button from "components/Button/Button"
import Card from "components/Card/Card"

import ExerciseInstructions from "./subcomponents"
import "./ExerciseComponent.scss"
import { Translation } from "react-i18next"
import ExerciseHints from "./subcomponents/ExerciseHints"

export const DEFAULT_STATES = {
  INSTRUCTIONS_SHOWING: -1,
  STARTING: -2,

  FINISHING: -3,
  FINISHED: -4,
}

export default class ExerciseComponent extends StateComponent {
  static usedModules = []
  static exerciseClass = "ExerciseComponent"

  instructions = {
    name: "",
    steps: {},
    imageHorizontal: "",
    imageVertical: "",
  }

  hints = []

  currentStateCallbackTimeout
  modules

  static hasPointsBar = true
  maxPoints = 0
  timePerQuestionSeconds = 0
  instruction
  hidePoints = false
  negativePointsAllowed = false

  static defaultParameters = {}
  parameters = {}

  finishData = {}
  instructionsAvailable = false

  static shuffleAnswers(state) {
    for (let question of state.questions) {
      question.answers = shuffle(question.answers)
    }
  }

  static processAnswers(state, defaultValues) {
    for (let question of state.questions) {
      for (let answer of question.answers) {
        defaults(answer, defaultValues)
      }
    }
  }

  initInstructions() {}
  initHints() {}

  constructor(props, startingState = DEFAULT_STATES.STARTING) {
    super(props, startingState)
    this.initInstructions(props)

    this.modules = []

    const { questions, parameters, t } = props
    const showInstructions = parameters.showInstructions ?? true

    this.initHints(parameters, t)

    this.hidePoints = parameters.hidePoints

    if (questions) {
      this.state = {
        ...this.state,
        questions: cloneDeep(questions),
        questionIndex: 0,
      }

      if (this.constructor.maxPoints) {
        this.maxPoints = this.constructor.maxPoints(questions, parameters)
      }
    }

    if (this.hints) {
      this.state = {
        ...this.state,
        currentHint: 0,
      }
    }

    this.state = {
      ...this.state,
      points: 0,
      clockId: 0,
    }

    for (const exerciseModule of this.usedModules(questions, parameters)) {
      this.modules.push(exerciseModule)
    }

    if (parameters) {
      this.parameters = {
        ...this.constructor.defaultParameters,
        ...parameters,
      }

      if (parameters["modules"] !== undefined) {
        for (const [moduleType, moduleParameters] of Object.entries(parameters["modules"])) {
          const exerciseModule = ModuleFactory.getModuleForType(moduleType, moduleParameters)
          if (exerciseModule.isActive()) {
            this.modules.push(exerciseModule)
          }
        }
      }
    }

    this.instructionsAvailable = showInstructions
      ? defaultTo(Object.values(this.instructions.steps), []).length > 0
      : false

    if (this.instructionsAvailable) {
      this.state.current = DEFAULT_STATES.INSTRUCTIONS_SHOWING
    }

    this.setModuleStateActions()
  }

  usedModules(questions, parameters) {
    return []
  }

  setModuleStateActions = () => {
    for (const exerciseModule of this.modules) {
      for (const info of exerciseModule.stateActions()) {
        this.onState(info.state, info.action)
      }
    }
  }

  render() {
    let exercise
    if (
      !this.inStates([
        DEFAULT_STATES.INSTRUCTIONS_SHOWING,
        DEFAULT_STATES.STARTING,
        DEFAULT_STATES.FINISHING,
        DEFAULT_STATES.FINISHED,
      ])
    ) {
      exercise = this.renderExercise(this.state, this.props)
    } else if (this.inState(DEFAULT_STATES.STARTING) && !this.instructionsAvailable) {
      setTimeout(this.startGame)
    }

    const { clockId, points } = this.state
    const clockDisabled = this.timePerQuestionSeconds === 0

    return (
      <AnimatedElement
        className={classNames("ExerciseComponent", this.constructor.exerciseClass)}
        visible={!this.inState(DEFAULT_STATES.FINISHED)}
      >
        <Translation ns={["common"]}>
          {(t) => (
            <>
              <AnimatedElement
                visible={this.inStates([
                  DEFAULT_STATES.INSTRUCTIONS_SHOWING,
                  DEFAULT_STATES.STARTING,
                ])}
              >
                <Container className="start-screen">
                  {this.instructions.steps && Object.values(this.instructions.steps).length > 0 && (
                    <ExerciseInstructions
                      name={this.instructions.name}
                      steps={this.instructions.steps}
                      imageHorizontal={this.instructions.imageHorizontal}
                      imageVertical={this.instructions.imageVertical}
                      onFinish={this.startGame}
                    />
                  )}
                </Container>
              </AnimatedElement>
              <AnimatedElement
                className="exercise-container"
                visible={
                  !this.inStates([
                    DEFAULT_STATES.INSTRUCTIONS_SHOWING,
                    DEFAULT_STATES.STARTING,
                    DEFAULT_STATES.FINISHING,
                    DEFAULT_STATES.FINISHED,
                  ])
                }
              >
                <PointsBar
                  visible={
                    this.constructor.hasPointsBar &&
                    !this.inStates([
                      DEFAULT_STATES.INSTRUCTIONS_SHOWING,
                      DEFAULT_STATES.STARTING,
                      DEFAULT_STATES.FINISHING,
                      DEFAULT_STATES.FINISHED,
                    ])
                  }
                  negativePointsAllowed={this.negativePointsAllowed}
                  hidePoints={this.hidePoints}
                  points={points}
                  maxPoints={this.maxPoints}
                  hideClock={clockDisabled}
                  timeLimit={this.timePerQuestionSeconds}
                  clockRunning={!clockDisabled && this.isClockRunning()}
                  clockId={clockId}
                  onTimeRanOut={this.timeRanOut}
                  clockWarningSeconds={Math.ceil(this.timePerQuestionSeconds / 3)}
                  instruction={this.instruction}
                  onSecondPassed={this.onSecondPassed}
                />
                {this.hints.length > 0 && <ExerciseHints hints={this.hints} />}
                {exercise}
              </AnimatedElement>
              <AnimatedElement
                visible={this.inStates(DEFAULT_STATES.FINISHING)}
                animation={AnimatedElement.AnimationTypes.fade}
                appearDelayMs={1000}
                fullSize={true}
              >
                {this.inStates(DEFAULT_STATES.FINISHING) && this.renderSummary()}

                <AnimatedElement
                  visible={this.inStates(DEFAULT_STATES.FINISHING)}
                  animation={AnimatedElement.AnimationTypes.popOut}
                >
                  <Button onClick={this.finish} big>
                    {t("continue")}
                  </Button>
                </AnimatedElement>
              </AnimatedElement>
            </>
          )}
        </Translation>
      </AnimatedElement>
    )
  }

  renderSummary = () => {
    let summaryComponents = []

    for (const exerciseModule of this.modules) {
      if (exerciseModule.shouldShowSummary()) {
        summaryComponents.push(exerciseModule.renderSummary())
      }
    }

    if (summaryComponents.length > 0) {
      return (
        <Card className="summary">
          {summaryComponents.map((component, index) => (
            <section key={index}>{component}</section>
          ))}
        </Card>
      )
    } else {
      setTimeout(this.finish)
      return ""
    }
  }

  instructionsShown = () => {
    this.setCurrentState(DEFAULT_STATES.STARTING)
  }

  startGame() {
    console.warn("[ExerciseComponent] No startGame() defined in exercise.")
  }

  timeRanOut() {
    console.warn("[ExerciseComponent] No timeRanOut() defined in exercise.")
  }

  renderExercise(state, props) {
    return ""
  }

  isClockRunning() {
    return false
  }

  changeClockId() {
    this.setState((state) => ({
      clockId: state.clockId + 1,
    }))
  }

  onSecondPassed = () => {}

  _questionAppeared(question) {
    this.changeClockId()

    for (const exerciseModule of this.modules) {
      exerciseModule.questionAppeared(question)
    }
  }

  questionAppeared = (question) => {
    this._questionAppeared(question)
  }

  _answerChosen(answer, data) {
    for (const exerciseModule of this.modules) {
      exerciseModule.answerChosen(answer, data)
    }
  }

  answerChosen = (answer, data) => {
    this._answerChosen(answer, data)
  }

  _finish = (sendPoints = true, otherData = this.finishData) => {
    this.setCurrentState(
      DEFAULT_STATES.FINISHED,
      () => {
        let events = []

        for (const exerciseModule of this.modules) {
          if (exerciseModule.hasEvents) {
            events.push(...exerciseModule.getEvents())
          }
        }

        let data = {}
        if (sendPoints) {
          data["points"] = this.state.points
        }

        if (events.length > 0) {
          data["events"] = events
        }

        if (otherData) {
          data["other"] = otherData
        }

        this.props.onFinish(data)
      },
      1100
    )
  }

  finish = (sendPoints, otherData) => {
    this._finish(sendPoints, otherData)
  }
}
