import React, { useCallback, useEffect, useRef } from "react"
import { Group, Image, Rect } from "react-konva"
import useImage from "use-image"
import useHVPosition from "../../../hooks/screen/useHVPosition"
import COLORS from "../../../enums/colors"
import { PUZZLE_HEIGHT, PUZZLE_WIDTH } from "./PuzzleAnswer"
import Konva from "konva"
import { MAX_NARROWER, MAX_WIDER } from "../../../hooks/screen/useGameSize"
import { BORDER_RADIUS } from "../../../enums/theme"

export const PUZZLE_IMAGE_WIDTH = [0.35, 0.6]
export const PUZZLE_IMAGE_HEIGHT = [
  (((PUZZLE_IMAGE_WIDTH[0] * 2) / 3) * MAX_WIDER) / MAX_NARROWER,
  (((PUZZLE_IMAGE_WIDTH[1] * 2) / 3) * MAX_NARROWER) / MAX_WIDER,
]
export const PUZZLE_IMAGE_X = [(1 - PUZZLE_IMAGE_WIDTH[0]) / 2, (1 - PUZZLE_IMAGE_WIDTH[1]) / 2]
export const PUZZLE_IMAGE_Y = [0.41, 0.4]
export const MISSING_PIECE_ID = "missing-piece"

const PuzzleImage = ({ image, revealed }) => {
  const { x, y, width, height } = useHVPosition({
    x: PUZZLE_IMAGE_X,
    y: PUZZLE_IMAGE_Y,
    width: PUZZLE_IMAGE_WIDTH,
    height: PUZZLE_IMAGE_HEIGHT,
  })

  const { width: puzzle_width, height: puzzle_height } = useHVPosition({
    width: PUZZLE_WIDTH,
    height: PUZZLE_HEIGHT,
  })
  const [k_image] = useImage(image)

  const tween_ref = useRef()
  const missing_piece_ref = useRef()

  const show = useCallback(() => {
    if (tween_ref.current) tween_ref.current.pause()

    tween_ref.current = new Konva.Tween({
      node: missing_piece_ref.current,
      duration: 0.5,
      opacity: 0,
      stroke: COLORS.SUCCESS,
      strokeWidth: width / 5,

      easing: Konva.Easings.EaseInOut,
    })
    tween_ref.current.play()
  }, [width])

  const hide = useCallback(() => {
    if (tween_ref.current) tween_ref.current.pause()

    tween_ref.current = new Konva.Tween({
      node: missing_piece_ref.current,
      duration: 0.1,
      opacity: 1,
      strokeWidth: 0,

      easing: Konva.Easings.EaseInOut,
    })
    tween_ref.current.play()
  }, [])

  useEffect(() => {
    if (revealed) {
      show()
    } else {
      hide()
    }
  }, [revealed, show, hide])

  return (
    <Group id="PuzzleImage" x={x} y={y}>
      <Image
        id="image"
        image={k_image}
        width={width}
        height={height}
        fill={COLORS.BRIGHT}
        stroke={COLORS.BRIGHT}
        strokeWidth={width / 25}
      />
      <Rect
        ref={(node) => (missing_piece_ref.current = node)}
        id={MISSING_PIECE_ID}
        x={(width - puzzle_width) / 2}
        y={(height - puzzle_height) / 2}
        width={puzzle_width}
        height={puzzle_height}
        fill={COLORS.BRIGHT}
        stroke={COLORS.SUCCESS}
        strokeWidth={0}
        cornerRadius={BORDER_RADIUS > 0 ? BORDER_RADIUS : undefined}
      />
    </Group>
  )
}

export default PuzzleImage
