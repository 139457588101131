import React, { useEffect, useRef } from "react"

import Card from "components/Card/Card"
import Images from "lib/Images"

import "./MatchCategories.scss"
import { isPointInRectangle } from "lib/Geometry"
import clsx from "clsx"

export default function MatchCategories({
  isQuestionShown,
  categories,
  draggedPosition,
  onCategoryHit,
}) {
  const categoryHitAction = (categoryId) => () => {
    onCategoryHit(categoryId)
  }

  return (
    <div className={clsx("MatchCategories", { "no-question": !isQuestionShown })}>
      {Object.entries(categories).map(([index, category]) => {
        return category?.name ? (
          <MatchCategory
            key={index}
            category={category}
            draggedPosition={draggedPosition}
            onCategoryHit={categoryHitAction(index)}
            small={!!categories[3]?.name}
          />
        ) : null
      })}
    </div>
  )
}

function MatchCategory({ category, draggedPosition, onCategoryHit, small }) {
  const categoryRef = useRef(null)

  useEffect(() => {
    function wasDraggedToCategory() {
      // debugger
      if (draggedPosition) {
        const cardRect = categoryRef.current.getBoundingClientRect()
        return isPointInRectangle(draggedPosition, cardRect)
      }

      return false
    }

    if (wasDraggedToCategory()) {
      onCategoryHit()
    }
  }, [draggedPosition, onCategoryHit])

  function getImageStyle() {
    return {
      backgroundImage:
        category.image?.[0] === "/"
          ? `url("${category.image}")`
          : Images.getCSSImageUrl(category.image, Images.SIZES.LARGE, Images.PROPORTIONS.P3x2),
    }
  }

  return (
    <Card className={clsx("category", { small })} innerRef={categoryRef}>
      <div className="image" style={getImageStyle()} />
      <div className="name">{category.name}</div>
    </Card>
  )
}
