import React from "react"
import { Stage } from "react-konva"
import useGameSize from "../hooks/screen/useGameSize"

import "./FullStage.scss"

export default function FullStage({ children, stage_ref, ...other }) {
  const { proportions, width, height } = useGameSize()

  return (
    <Stage
      ref={(node) => {
        if (stage_ref) stage_ref.current = node
      }}
      className="FullStage"
      width={width}
      height={height}
      scale={{ x: proportions, y: proportions }}
      {...other}
    >
      {children}
    </Stage>
  )
}
