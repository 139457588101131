import React, { useEffect, useMemo } from "react"
import { Group, Layer } from "react-konva"

import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import Element from "../../components/Element"
import FullStage from "../../components/FullStage"
import { useAnswersState } from "../../state/exercise/$answers"

import BubbleAnswers from "./subcomponents/BubbleAnswers"
import BubblesBorder from "./subcomponents/BubblesBorder"

import "./BubblesExercise.scss"

const FLOAT_DURATIONS = {
  slow: 12,
  medium: 10,
  fast: 8,
}

const BubblesExercise = ({
  state,
  inAnswersState,
  question,
  answers,
  answerChosen,
  resetTimestamp,
  parameters,
}) => {
  const { extendAnswers } = useAnswersState()
  const floatDuration = useMemo(() => FLOAT_DURATIONS[parameters.floatSpeed ?? "medium"], [
    parameters,
  ])

  useEffect(() => {
    extendAnswers({ active: false })
    // eslint-disable-next-line
  }, [question])

  return (
    <div className="TrueFalseExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage>
          <Layer>
            <Group>
              <BubbleAnswers
                key={question.id}
                answers={answers}
                answerChosen={answerChosen}
                answerAppeared={resetTimestamp}
                floatDuration={floatDuration}
              />
            </Group>
          </Layer>
          <Layer x={0} y={0}>
            <BubblesBorder />
          </Layer>
        </FullStage>
      </Element>
    </div>
  )
}

export default BubblesExercise
