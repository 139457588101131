import React, { ReactNode, useCallback, useEffect, useRef } from "react"
// import clsx from 'clsx';

import "./ElevatorCard.scss"
// import Card from "components/Card/Card";
// import Button from "components/Button/Button";

// import { DOOR_ANIMATION_MS } from './ElevatorDoors';
import { Group, Rect, Text } from "react-konva"
import Konva from "konva"
import useGameSize from "../../../hooks/screen/useGameSize"
import COLORS from "enums/colors"
import ButtonK from "../../../@konva/ui/ButtonK"
import useHVPosition from "../../../hooks/screen/useHVPosition"
import defaultTo from "lodash/defaultTo"
import Node = Konva.Node

export interface ElevatorCardI {
  floor: number
  currentFloor: number
  content: string
  onAnswerChosen: () => void
}

interface MutableRefObjectI {
  [key: string]: React.MutableRefObject<any>
}

export default function ElevatorCard({
  floor,
  currentFloor,
  content,
  onAnswerChosen,
}: ElevatorCardI) {
  const { height: gameHeight } = useGameSize()
  const {
    x: floorX,
    y: floorY,
    width: floorWidth,
    height: floorHeight,
  } = useHVPosition(
    {
      x: [100, 50],
      y: [435, 650],
      width: [600, 600],
      height: [550, 800],
    },
    false
  )
  const doorWidth = floorWidth / 2 - 1
  const rightDoorPosition = floorWidth / 2 + 2

  const elevatorR = useRef<Node>()
  const interruptedR = useRef<boolean>(false)
  const animatingElevatorR = useRef<boolean>(false)
  const tweenR: MutableRefObjectI = {
    elevator: useRef<Konva.Tween>(),
    doorLeft: useRef<Konva.Tween>(),
    doorRight: useRef<Konva.Tween>(),
  }
  const doorsR: MutableRefObjectI = {
    left: useRef<ReactNode>(),
    right: useRef<ReactNode>(),
  }

  const calculatePosition = useCallback(() => {
    return (defaultTo(currentFloor, -1) - floor) * (floorHeight + 2 * gameHeight) + floorY
  }, [floor, currentFloor, floorHeight, gameHeight, floorY])

  const startY = useRef<number>(calculatePosition())

  const stopAnimations = useCallback(() => {
    if (animatingElevatorR.current) {
      interruptedR.current = true
      tweenR.elevator.current.pause()
    }
  }, [tweenR.elevator])

  const openDoor = useCallback(() => {
    for (let [tween, ref, direction] of [
      [tweenR.doorLeft, doorsR.left.current, "left"],
      [tweenR.doorRight, doorsR.right.current, "right"],
    ]) {
      if (tween !== undefined && ref) {
        tween.current = new Konva.Tween({
          node: ref,
          duration: 0.75,
          width: 0,
          x: direction === "left" ? 0 : floorWidth,
          easing: Konva.Easings.EaseInOut,
        })
        tween.current.play()
      }
    }
  }, [floorWidth, doorsR.left, doorsR.right, tweenR.doorLeft, tweenR.doorRight])

  const moveElevator = useCallback(() => {
    if (!openDoor) return

    if (currentFloor !== undefined && elevatorR.current) {
      animatingElevatorR.current = true
      tweenR.elevator.current = new Konva.Tween({
        node: elevatorR.current,
        duration: 0.5,
        y: calculatePosition(),
        easing: Konva.Easings.EaseInOut,
        onFinish: () => {
          if (interruptedR.current) {
            interruptedR.current = false
          } else {
            openDoor()
          }
          animatingElevatorR.current = false
        },
      })
      tweenR.elevator.current.play()
    }
  }, [calculatePosition, openDoor, tweenR.elevator, currentFloor])

  const closeDoor = useCallback(() => {
    if (!moveElevator || !stopAnimations) return
    stopAnimations()

    for (let [tween, ref, direction] of [
      [tweenR.doorLeft, doorsR.left.current, "left"],
      [tweenR.doorRight, doorsR.right.current, "right"],
    ]) {
      if (tween !== undefined && ref) {
        tween.current = new Konva.Tween({
          node: ref,
          duration: 0.5,
          width: doorWidth,
          x: direction === "left" ? 0 : rightDoorPosition,
          easing: Konva.Easings.EaseInOut,
          onFinish: moveElevator,
        })
        tween.current.play()
      }
    }
  }, [
    rightDoorPosition,
    doorWidth,
    stopAnimations,
    doorsR.left,
    doorsR.right,
    moveElevator,
    tweenR.doorLeft,
    tweenR.doorRight,
  ])

  useEffect(() => {
    if (!closeDoor || currentFloor === undefined || currentFloor < 0) return

    closeDoor()
    // eslint-disable-next-line
  }, [currentFloor])

  return (
    <>
      <Group ref={(node) => (elevatorR.current = node)} x={floorX} y={startY.current}>
        <Rect
          width={floorWidth}
          height={floorHeight}
          fill={COLORS.MAIN}
          stroke={COLORS.BRIGHT}
          strokeWidth={15}
        />
        <Text
          x={25}
          width={floorWidth - 50}
          height={floorHeight * 0.7}
          align="center"
          verticalAlign="middle"
          fill={COLORS.WHITE}
          fontFamily={COLORS.FONT}
          text={content}
          fontSize={40}
          lineHeight={1.25}
        />
        <ButtonK
          x={floorWidth * 0.15}
          y={floorHeight * 0.7}
          width={floorWidth * 0.7}
          height={floorHeight * 0.15}
          text="Wysiadam"
          onClick={onAnswerChosen}
        />
        <Rect
          id="left-door"
          ref={(n) => (doorsR.left.current = n)}
          width={doorWidth}
          height={floorHeight}
          fill={COLORS.BRIGHT}
        />
        <Rect
          id="right-door"
          ref={(n) => (doorsR.right.current = n)}
          x={rightDoorPosition}
          width={doorWidth}
          height={floorHeight}
          fill={COLORS.BRIGHT}
        />
      </Group>
    </>
  )
}
