import imgHorizontal from "images/exercises/cleaning/exercise-horizontal.jpg"
import imgVertical from "images/exercises/cleaning/exercise-vertical.jpg"
import { QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"

const cleaning_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  parameters: {
    points: {
      per_correct_answer: 2,
      per_incorrect_answer: -1,
    },
    answers: {
      finish_type: QUESTION_FINISH_TYPES.ALL_CORRECT,
    },
  },
}

export default cleaning_config
