import React, { useEffect, useRef } from "react"
import { Group, Image, Rect, Text } from "react-konva"
import Konva from "konva"
import useHVPosition from "../../../hooks/screen/useHVPosition"
import COLORS from "../../../enums/colors"
import useImage from "use-image"
import { MAX_NARROWER, MAX_WIDER } from "../../../hooks/screen/useGameSize"

export const TRUE_FALSE_ANSWER_STATE = {
  APPEARING: "APPEARING",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  DISAPPEARING: "DISAPPEARING",
}

export const PUZZLE_IMAGE_WIDTH = [0.44, 0.8]
export const PUZZLE_IMAGE_HEIGHT = [
  (((PUZZLE_IMAGE_WIDTH[0] * 2) / 3) * MAX_WIDER) / MAX_NARROWER,
  (((PUZZLE_IMAGE_WIDTH[1] * 2) / 3) * MAX_NARROWER) / MAX_WIDER,
]

const S = { ...TRUE_FALSE_ANSWER_STATE }

const TrueFalseAnswer = ({ state, answer, correct }) => {
  const {
    parameters: { image },
  } = answer
  const [kImage] = useImage(image)

  const appearing_coords = useHVPosition({
    x: [-PUZZLE_IMAGE_WIDTH[0] * 1.1, -PUZZLE_IMAGE_WIDTH[1]],
  })
  const { x, y, width, height } = useHVPosition({
    x: [(1 - PUZZLE_IMAGE_WIDTH[0]) / 2, (1 - PUZZLE_IMAGE_WIDTH[1]) / 2],
    y: [0.35, 0.35],
    width: PUZZLE_IMAGE_WIDTH,
    height: PUZZLE_IMAGE_HEIGHT,
  })
  const disappearing_coords = useHVPosition({
    x: [1, 1],
    y: [1.2, 1.3],
  })

  const node_ref = useRef()

  useEffect(() => {
    switch (state) {
      case S.APPEARING:
        new Konva.Tween({
          node: node_ref.current,
          duration: 0.5,
          x,

          easing: Konva.Easings.BackEaseOut,
        }).play()
        break

      case S.DISAPPEARING:
        new Konva.Tween({
          node: node_ref.current,
          duration: 0.75,
          x: disappearing_coords.x,
          y: correct ? y : disappearing_coords.y,
          rotation: correct ? 0 : 180,

          easing: Konva.Easings.BackEaseIn,
          onFinish: () => {
            node_ref.current.x(appearing_coords.x)
            node_ref.current.y(y)
            node_ref.current.rotation(0)
          },
        }).play()
        break

      default:
        break
    }
    // eslint-disable-next-line
  }, [state, correct])

  return (
    <Group id="TrueFalseAnswer" ref={(node) => (node_ref.current = node)} x={x} y={y}>
      {!!image && (
        <Image
          x={0}
          y={0}
          id="answer-bg"
          image={kImage}
          fill={COLORS.MAIN}
          stroke={COLORS.BRIGHT}
          strokeWidth={width / 50}
          width={width}
          height={height}
        />
      )}

      <Rect
        x={0}
        y={0}
        id="answer-overlay"
        fill={!image ? COLORS.MAIN : "#000000"}
        stroke={!image ? COLORS.BRIGHT : undefined}
        strokeWidth={width / 100}
        width={width}
        height={height}
        opacity={!image ? 1 : 0.6}
      />
      <Text
        x={0.05 * width}
        y={0.05 * height}
        id="answer-text"
        align="center"
        verticalAlign="middle"
        fill={COLORS.WHITE}
        fontFamily={COLORS.FONT}
        text={answer.content}
        fontSize={40}
        lineHeight={1.25}
        width={0.9 * width}
        height={0.9 * height}
      />
    </Group>
  )
}

export default TrueFalseAnswer
