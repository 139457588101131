import React from 'react';
import Button from "components/Button/Button";

import "./ParachutesCategories.scss"

export const STATES = {
  MOVING: 0,
  PAUSED: 1,
};

export default function ParachutesCategories({categories, categorySelected$, disabled}) {
  let buttonRef = [];
  categories.forEach(() => {
    buttonRef.push(React.createRef());
  });

  return <div
    className="ParachutesCategories"
  >
    {categories.map((category, index) => (
      <Button key={index} ref={buttonRef[index]}
        className="category"
        onClick={categorySelected$(index, buttonRef[index])}

        disabled={disabled}
      >
        {category}
      </Button>
    ))}
  </div>
}