import React, { useRef } from "react"
import { Circle, Group } from "react-konva"
import Konva from "konva"

import COLORS from "../../../enums/colors"
import useHVPosition from "../../../hooks/screen/useHVPosition"
import { random, range } from "lodash"
import { useEffectOnce } from "react-use"

const BubblesBorder = () => {
  const { x: fullWidth, y: fullHeight, width: radius, height: maxVariance } = useHVPosition({
    x: [1, 1],
    y: [1.12, 1.02],
    width: [0.1, 0.1],
    height: [0.015, 0.02],
  })

  const circleR = useRef({})

  useEffectOnce(() => {
    Object.values(circleR.current).forEach((node) => {
      const tween = new Konva.Tween({
        node: node,
        duration: random(0.5, 1, true),
        easing: Konva.Easings.EaseInOut,

        y: random(-maxVariance, maxVariance),
        onFinish: () => tween.reverse(),
        onReset: () => tween.play(),
      }).play()
    })
  })

  return (
    <Group x={0} y={fullHeight}>
      {range(10).map((id) => (
        <Circle
          ref={(n) => (circleR.current[id] = n)}
          key={id}
          x={fullWidth * (id / 9)}
          radius={radius}
          fill={COLORS.CLICKABLE}
        />
      ))}
    </Group>
  )
}

export default BubblesBorder
