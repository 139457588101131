import imgHorizontal from "images/exercises/puzzle/exercise-horizontal.jpg"
import imgVertical from "images/exercises/puzzle/exercise-vertical.jpg"
import { SIDES } from "../../state/$sprint"
import { POINT_TYPES, QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"

const puzzle_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  parameters: {
    sides: {
      [SIDES.BOTTOM]: true,
    },
    answers: {
      sounds: false,
      finish_type: QUESTION_FINISH_TYPES.FIRST_CORRECT,
    },
    points: {
      per_correct_answer: POINT_TYPES.ANSWERS_LEFT_COUNT,
    },
  },
}

export default puzzle_config
