import ExerciseTimeActions from "./ExerciseTimeActions"
import Actions from "../../actions"
import ExerciseActions from "../ExerciseActions"

const initialState = {
  available: false,
  perQuestion: 0,

  isRunning: false,
  current: 0,
};

const ExerciseTimeReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case (ExerciseTimeActions.T.RESET):
      return {
        ...state,
        current: state.perQuestion,
      }

    case (ExerciseTimeActions.T.START):
      return {
        ...state,
        isRunning: true,
      }

    case (ExerciseTimeActions.T.PAUSE):
      return {
        ...state,
        isRunning: false,
      }

    case (ExerciseTimeActions.T.SECOND_PASSED):
      if (!state.isRunning)
        return state

      const current = Math.max(state.current - 1, 0)
      const isRunning = state.isRunning && current > 0

      return {
        ...state,
        current,
        isRunning,
      }

    case (ExerciseActions.T.LOAD):
      const {data} = payload

      return {
        ...state,
        available: data.parameters.time.available,
        perQuestion: data.parameters.time.perQuestion
      }

    case (Actions.T.RESET):
      return {...initialState,
      }

    default:
      return state
  }
}

export default ExerciseTimeReducer