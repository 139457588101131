import imgHorizontal from "images/exercises/puzzle/exercise-horizontal.jpg"
import imgVertical from "images/exercises/puzzle/exercise-vertical.jpg"
import { QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"
import DistributionExerciseInstructions from "./subcomponents/DistributionExerciseInstructions"

const distribution_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  parameters: {
    showPointsBar: false,
    instructions: {
      InstructionsComponent: DistributionExerciseInstructions,
      showContinue: true,
    },
    answers: {
      sounds: false,
      feedback: false,
      shuffle: false,
      finish_type: QUESTION_FINISH_TYPES.FIRST_CORRECT,
    },
    points: {
      show: false,
      per_correct_answer: 0,
    },
    time: {
      enabled: false,
    },
  },
}

export default distribution_config
