import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { SingletonHooksContainer } from "react-singleton-hook"

import "./index.scss"
import "./config"
import "./i18n"

import App from "./app/App"

// import registerServiceWorker from './registerServiceWorker';

import WebFont from "webfontloader"
import { Provider } from "react-redux"

import store from "./redux/store"
import { LoadingSprint } from "./app/Sprint"

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<LoadingSprint className="loading" />}>
      <SingletonHooksContainer />
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
)

// registerServiceWorker();

WebFont.load({
  google: {
    families: ["Lato:400,700:latin,latin-ext", "Montserrat:400,700:latin,latin-ext", "sans-serif"],
  },
  custom: {
    families: ["Font Awesome 5 Icons:400,900", "Font Awesome 5 Brands:400"],
    urls: ["//use.fontawesome.com/releases/v5.1.0/css/all.css"],
  },
})
