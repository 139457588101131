import React from "react"

import "./css/SprintBar.scss"
import { useTranslation } from "react-i18next"

import { ReactComponent as LogoSVG } from "images/logo-white.svg"

function SprintBar({
  points = 0,
  maxPoints = 100,
  level = 1,
  maxLevels = 1,
  parameters = {},

  // helpClick,
  // skipClick,
}) {
  const { t } = useTranslation("common")

  const { hidePoints = false } = parameters

  return (
    <div className="SprintBar">
      <div className="points">
        <p>
          {!hidePoints && maxPoints > 0 && (
            <>
              {t("sprintScore")}:{" "}
              <span className="value">
                {points} / {maxPoints}
              </span>
            </>
          )}
        </p>
      </div>
      <div className="levels">
        <p>
          {maxLevels > 1 && (
            <strong>
              {level} / {maxLevels}
            </strong>
          )}
        </p>
      </div>
      <div className="logo">
        <div className="logo-description">
          <span className="made-by">Made by</span>
          <span className="sprintech-name">SprinTECH Learning</span>
        </div>
        <div className="logo-svg">
          <LogoSVG alt="SprinTECH Learning" />
        </div>
      </div>
    </div>
  )
}

export default SprintBar
