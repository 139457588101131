import imgHorizontal from "images/exercises/true_false/exercise-horizontal.jpg"
import imgVertical from "images/exercises/true_false/exercise-vertical.jpg"
import { QUESTION_FINISH_TYPES } from "../../@exercises/configs/default_config"
import { SIDES } from "../../state/$sprint"

const true_false_config = {
  parameters: {
    hasPostExerciseScreen: true,
    sides: {
      [SIDES.LEFT]: true,
      [SIDES.RIGHT]: true,
      [SIDES.BOTTOM]: true,
    },
    time: {
      perQuestion: 0,
    },
    answers: {
      sounds: false,
      finish_type: QUESTION_FINISH_TYPES.ALL_ANSWERS,
    },
  },
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  maxPoints: function () {
    let points = 0
    const { per_correct_answer } = this.parameters.points

    for (let question of this.questions) {
      points += question.answers.length * per_correct_answer
    }

    return points
  },
}

export default true_false_config
