import React, { Component, useMemo } from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import _, { defaults, defaultTo } from "lodash"
import clsx from "clsx"
import { useTranslation, withTranslation } from "react-i18next"
import remarkSubSuper from "remark-sub-super"

import "./MessageScreen.scss"
import Card from "components/Card/Card"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import Button from "components/Button/Button"

import imgQuestion from "./img/question.jpg"
import finishQuestion from "./img/finish.jpg"
import PlayArea from "lib/PlayArea"

const ANIMATION_SPEED = 1000

const IMAGES = {
  question: imgQuestion,
  finish: finishQuestion,
  colorful: require("./img/colorful.jpg"),
}

const customRenderers = {
  link: ({ href, children }) => (
    <a href={href} target="_blank" rel="noopener noreferrer">
      {children}
    </a>
  ),
}

class MessageScreen extends Component {
  static propTypes = {
    parameters: PropTypes.shape({
      header: PropTypes.string,
      content: PropTypes.string,
      image: PropTypes.oneOf(["question", "finish", "colorful"]),
      imageUrl: PropTypes.string,
      imageUrlVertical: PropTypes.string,
      buttonText: PropTypes.string,
      refreshOnNext: PropTypes.bool,
      customUrlForNext: PropTypes.string,
      buttons: PropTypes.array,
      narrowImage: PropTypes.bool,
      imageTop: PropTypes.string,
      footer: PropTypes.array,
    }),
    image: PropTypes.string,
    imageFormat: PropTypes.string,
    header: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    html: PropTypes.bool,
    bigImage: PropTypes.bool,
    buttonText: PropTypes.string,
    browserWarning: PropTypes.bool,
    forVisa: PropTypes.bool,

    nextEnabled: PropTypes.bool,
    refreshEnabled: PropTypes.bool,

    goNextAction: PropTypes.func,
  }

  static defaultProps = {
    goNextAction: () => {},
  }

  static defaultParameters = {
    image: "question",
    imageFull: false,
    animation: AnimatedElement.AnimationTypes.fade,
    elevator: false,
    buttons: [],
    hideButton: false,
    redirect: false,
    narrowImage: false,
    footer: [],
  }

  constructor(props) {
    super(props)

    this.state = {
      visible: true,
    }

    this.descriptionRef = React.createRef()
  }

  goNext = () => {
    this.setState({
      visible: false,
    })

    setTimeout(this._goNext, ANIMATION_SPEED)
  }

  _goNext = () => {
    const { refreshOnNext, customUrlForNext } = this.props.parameters

    if (refreshOnNext) {
      this.reload()
    } else if (customUrlForNext) {
      this.goToUrl(customUrlForNext)
    } else {
      this.props.goNextAction()
    }
  }

  reload = () => {
    window.location.reload()
  }

  goToUrl = (url) => {
    window.location.href = url
  }

  render() {
    const { t } = this.props

    let {
      header,
      content,
      image,
      imageUrl,
      imageUrlVertical,
      imageFull,
      buttonText,
      animation,
      elevator,
      buttons,
      hideButton,
      redirect,
      narrowImage,
      imageTop,
      footer,
    } = _.defaults(this.props.parameters, MessageScreen.defaultParameters)

    if (redirect) {
      window.location.href = redirect
      return <div />
    }

    image = IMAGES[image]
    if (imageUrl) {
      if (imageUrlVertical && PlayArea.isVertical()) image = imageUrlVertical
      else image = imageUrl
    }

    const imageStyle = {
      backgroundImage: `url('${image}')`,
    }

    return (
      <AnimatedElement
        className={clsx("MessageScreen", {
          "full-image": imageFull,
          "custom-vertical": !!imageUrlVertical,
        })}
        visible={this.state.visible}
        animation={animation}
      >
        <Card className={clsx("message", { "narrow-image": narrowImage })}>
          {elevator && (
            <div className="doors">
              <div className="left-door" />
              <div className="right-door" />
            </div>
          )}
          <div className={"image"} style={imageStyle}>
            <img src={image} alt={image} />
          </div>
          {(header || content) && (
            <div className={"content"}>
              {imageTop && (
                <div className="image-top">
                  <img src={imageTop} alt="" />
                </div>
              )}
              {header && <h1>{header}</h1>}
              <ReactMarkdown
                plugins={[remarkSubSuper]}
                renderers={{
                  sub: "sub",
                  sup: "sup",
                  ...customRenderers,
                }}
                source={content}
              />
              {footer && footer.length > 0 && (
                <div className="footer">
                  {footer.map(({ text, url }, index) => (
                    <a href={url} target="_blank" rel="noopener noreferrer" key={index}>
                      {text}
                    </a>
                  ))}
                </div>
              )}
            </div>
          )}
        </Card>
        <div className={clsx("buttons", { multiple: !!buttons })}>
          {!hideButton && (
            <Button onClick={this.goNext} big>
              {defaultTo(buttonText, t("continue"))}
            </Button>
          )}
          {buttons.map(({ text, url }, index) => (
            <Button key={index} onClick={() => (window.location.href = url)} big={true}>
              {text}
            </Button>
          ))}
        </div>
      </AnimatedElement>
    )
  }
}

export const FinishMessage = ({ parameters, points, ...other }) => {
  const { t } = useTranslation("common")
  const processedParameters = useMemo(() => {
    let newParameters = defaults(parameters, {
      header: t("finishMessage.title"),
      content: t("finishMessage.content"),
      buttonText: t("finishMessage.button"),
    })

    const username = window.localStorage.getItem("username")
    const password = window.localStorage.getItem("password")

    const processVariables = (processedString) => {
      return processedString
        .replace("{{username}}", username)
        .replace("{{password}}", password)
        .replace("{{points}}", points)
    }

    newParameters.content = processVariables(newParameters.content ?? "")
    newParameters.customUrlForNext = processVariables(newParameters.customUrlForNext ?? "")

    return newParameters
  }, [t, points, parameters])

  console.log(parameters)

  const { forceNextUrl, customUrlForNext } = parameters

  if (forceNextUrl) {
    window.location.href = customUrlForNext
    return ""
  } else {
    return <MessageScreen parameters={processedParameters} t={t} {...other} />
  }
}

export default withTranslation("common")(MessageScreen)
