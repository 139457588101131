import React, {useEffect, useRef} from 'react';
import clsx from "clsx";

import {isPointInRectangle} from "lib/Geometry";

// import CauldronImg from "../img/cauldron.svg";
import {ReactComponent as CauldronImg} from "../img/cauldron.svg";
import "./Cauldron.scss";

export default function Cauldron({draggedPosition, onHit, exploding, brewed, shake}) {
  const cauldronRef = useRef(null);

  useEffect(() => {
    function wasDraggedToCategory() {
      if (draggedPosition) {
        const cardRect = cauldronRef.current.getBoundingClientRect();
        return isPointInRectangle(draggedPosition, cardRect);
      }

      return false
    }

    if (wasDraggedToCategory()) {
      onHit();
    }
  }, [draggedPosition, onHit]);

  return (
    <div ref={cauldronRef}
      className={clsx("Cauldron", "shake-constant", {"exploding shake-hard": exploding}, {brewed}, shake)}
    >
      <div className="cauldron-container">

        <div className="bubbles">
          <div className="bubble bubble-1" />
          <div className="bubble bubble-2" />
          <div className="bubble bubble-3" />
        </div>
        <div className="star star-1">&#9733;</div>
        <div className="star star-2">&#9733;</div>
        <div className="star star-3">&#9733;</div>
        <div className="star star-4">&#9733;</div>
        <div className="star star-5">&#9733;</div>
        <div className="cauldron-svg">
          <CauldronImg className="image" />
        </div>
      </div>
    </div>
  )
}