import React, { Component } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import _ from "lodash"

import circleImg from "./img/circle.svg"
import check1Img from "./img/check-1.svg"
import check2Img from "./img/check-2.svg"
import likeImg from "./img/like.svg"
import lineImg from "./img/line.svg"
import "./AnimationCorrectExplosion.scss"

export default class AnimationCorrectExplosion extends Component {
  static propTypes = {
    visible: PropTypes.bool.isRequired,
    fixed: PropTypes.bool,

    position: PropTypes.array,
    x: PropTypes.number,
    y: PropTypes.number,
  }

  componentDidMount() {
    ;[circleImg, check1Img, check2Img, likeImg, lineImg].forEach((picture) => {
      const img = new Image()
      img.src = picture.fileName
    })
  }

  render() {
    const { visible, fixed } = this.props

    if (visible)
      return (
        <div
          className={classNames("AnimationCorrectExplosion", "visible", { fixed })}
          style={this.getStyling()}
        >
          <img
            alt={"Bum"}
            className={`animation-correct-explosion animation-correct-explosion-circle`}
            src={circleImg}
          />
          <img
            alt={"Bum"}
            className={`animation-correct-explosion animation-correct-explosion-check-1`}
            src={check1Img}
          />
          {_.range(1, 3).map((index) => {
            return (
              <img
                key={index}
                alt={"Bum"}
                className={`animation-correct-explosion animation-correct-explosion-check-2-${index}`}
                src={check2Img}
              />
            )
          })}
          {_.range(1, 4).map((index) => {
            return (
              <img
                key={index}
                alt={"Bum"}
                className={`animation-correct-explosion animation-correct-explosion-line-${index}`}
                src={lineImg}
              />
            )
          })}
          {_.range(1, 4).map((index) => {
            return (
              <img
                key={index}
                alt={"Bum"}
                className={`animation-correct-explosion animation-correct-explosion-like-${index}`}
                src={likeImg}
              />
            )
          })}
        </div>
      )
    else return ""
  }

  getStyling = () => {
    let { position, x, y } = this.props

    if (position) {
      x = position[0]
      y = position[1]
    }

    return {
      left: `${x}px`,
      top: `${y}px`,
    }
  }
}
