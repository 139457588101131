import { useCallback, useEffect, useRef, useState } from "react"
import useGameSize, { MAX_NARROWER, MAX_WIDER } from "./useGameSize"
import defaultTo from "lodash/defaultTo"

export interface HVPositionI {
  x: number
  y: number
  width: number
  height: number
}

export interface HVPositionParameterI {
  x?: [number, number]
  y?: [number, number]
  width?: [number, number]
  height?: [number, number]
}

function useHVPosition(
  { x = [0, 0], y = [0, 0], width = [0, 0], height = [0, 0] }: HVPositionParameterI,
  relative = true
): HVPositionI {
  const { isVertical } = useGameSize()
  const basePosition = useRef<HVPositionParameterI>({
    x: relative ? [x[0] * MAX_WIDER, x[1] * MAX_NARROWER] : x,
    y: relative ? [y[0] * MAX_NARROWER, y[1] * MAX_WIDER] : y,
    width: relative ? [width[0] * MAX_WIDER, width[1] * MAX_NARROWER] : width,
    height: relative ? [height[0] * MAX_NARROWER, height[1] * MAX_WIDER] : height,
  })

  const getCurrentPosition = useCallback(() => {
    const index = isVertical ? 1 : 0

    return {
      x: defaultTo(basePosition.current.x, [0, 0])[index],
      y: defaultTo(basePosition.current.y, [0, 0])[index],
      width: defaultTo(basePosition.current.width, [0, 0])[index],
      height: defaultTo(basePosition.current.height, [0, 0])[index],
    }
  }, [isVertical])

  const [position, setPosition] = useState<HVPositionI>(getCurrentPosition())

  useEffect(() => {
    setPosition(getCurrentPosition())
  }, [getCurrentPosition])

  return position
}

export default useHVPosition
