import React, { useMemo } from "react"
import Draggable from "react-draggable"
import clsx from "clsx"

import Card from "components/Card/Card"

import "./MatchAnswers.scss"
import ReactMarkdown from "react-markdown"

export default function MatchAnswers({ answers, setDraggedPosition, disabled, small }) {
  const answerDraggedAction = (answer) => (event) => {
    let positionedElement = event
    if (event.changedTouches && event.changedTouches.length > 0) {
      positionedElement = event.changedTouches[0]
    } else if (event.touches && event.touches.length > 0) {
      positionedElement = event.touches[0]
    }

    const position = [positionedElement.clientX, positionedElement.clientY]

    setDraggedPosition(position, answer)
  }

  const long = useMemo(
    () =>
      answers.length <= 4 &&
      answers.reduce((sum, answer) => sum + answer.content.length, 0) / answers.length > 50,
    [answers]
  )

  return (
    <div className={clsx("MatchAnswers", { "three-cols": [5, 6].includes(answers.length) })}>
      {answers.map((answer, index) => {
        return (
          <Draggable
            handle=".answer"
            // defaultPosition={{x: 0, y: 0}}
            position={{ x: 0, y: 0 }}
            onStop={answerDraggedAction(answer)}
            disabled={!answer.active || disabled}
            key={index}
          >
            <Card
              className={clsx("answer", {
                small,
                disabled,
                long,
                "very-long": answer.content.length > 100,
              })}
              isOpaque={!answer.active}
              isDraggable
            >
              <ReactMarkdown source={answer.content} />
            </Card>
          </Draggable>
        )
      })}
    </div>
  )
}
