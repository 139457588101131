import React, { Component } from "react"
import PropTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"

import AnimatedElement from "../AnimatedElement/AnimatedElement"

import "./InstructionCard.scss"
import { Translation } from "react-i18next"

class InstructionCard extends Component {
  secondsTimeout

  static propTypes = {
    visible: PropTypes.bool,

    mainText: PropTypes.string,
    markdown: PropTypes.bool,
    small: PropTypes.bool,

    countType: PropTypes.string,
    countCurrent: PropTypes.number,
    countMax: PropTypes.number,
  }

  static defaultProps = {
    mainText: "",
    visible: true,
    topText: "",
    markdown: false,
    small: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      time: props.timeLimit,
    }
  }

  render() {
    let { mainText, small, countMax } = this.props

    let topText = countMax > 1 ? this.getTopText() : ""

    return (
      <AnimatedElement
        visible={this.props.visible}
        className={classNames("InstructionCard", {
          small,
        })}
        animation={AnimatedElement.AnimationTypes.slideLeft}
        zIndex={1}
      >
        {/*<div className="left-filler" />*/}
        <div className="text">
          {countMax > 1 && <div className="count">{topText}</div>}
          <div className="content">
            <ReactMarkdown source={mainText} />
          </div>
        </div>
      </AnimatedElement>
    )
  }

  getTopText = () => {
    let { countType, countCurrent, countMax } = this.props

    if (countType !== undefined && countCurrent !== undefined && countMax !== undefined) {
      return (
        <Translation ns={"common"}>
          {(t) => `${countType} ${countCurrent} ${t("outOf")} ${countMax}`}
        </Translation>
      )
    } else {
      return ""
    }
  }
}

export default InstructionCard
