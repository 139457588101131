import React, { useEffect, useRef, useState } from "react"
import SzymonVotingExercise, { SzymonVotingExerciseAnswers } from "./SzymonVotingExercise"
import { FREQUENCY_DESCRIPTION } from "../VotingExercise"
import AnimatedElement from "../../../components/AnimatedElement/AnimatedElement"
import Card from "../../../components/Card/Card"
import Button from "../../../components/Button"

import "./TeamDysfunctionsVotingExercise.scss"

const VOTE_MAP = {
  6: 9,
  5: 7,
  4: 5,
  3: 4,
  2: 2,
  1: 0,
}

const TeamDysfunctionsVotingExercise = (props) => {
  return (
    <SzymonVotingExercise
      className="TeamDysfunctionsVotingExercise"
      voteMapCorrect={VOTE_MAP}
      voteMapIncorrect={VOTE_MAP}
      answersComponent={TeamDysfunctionsAnswers}
      ExerciseSummaryComponent={TeamDysfunctionsExerciseSummary}
      {...props}
    />
  )
}

const TeamDysfunctionsAnswers = (props) => {
  return <SzymonVotingExerciseAnswers description={FREQUENCY_DESCRIPTION} {...props} />
}

const TeamDysfunctionsScaleSummary = ({ points, title }) => {
  const [position, setPosition] = useState(0)
  const timeoutRef = useRef(null)

  useEffect(() => {
    timeoutRef.current = setTimeout(() => setPosition((points / 27) * 100), 250)

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [points])

  return (
    <div className="TeamDysfunctionsScaleSummary ScaleSummary">
      <div className="description">{title}</div>
      <div className="problem" />
      <div className="warning" />
      <div className="success" />
      <div className="slider" style={{ left: `${position}%` }} />
    </div>
  )
}

const TeamDysfunctionsExerciseSummary = ({ visible, onNext, summary }) => {
  return (
    <AnimatedElement visible={visible} className="ExerciseSummary">
      <Card>
        <h1 className="title">Wynik testu dysfunkcji zespołu</h1>
        {summary.map((questionInfo, index) => (
          <TeamDysfunctionsScaleSummary key={index} {...questionInfo} />
        ))}
      </Card>
      <Card className="legend">
        <div className="description">Jeżeli suwak jest na kolorze:</div>
        <div className="problem">
          <strong>czerwonym</strong> - ta dysfunkcja prawdopodobnie wymaga szybkiej interwencji
        </div>
        <div className="warning">
          <strong>żółtym</strong> - ta dysfunkcja prawdopodobnie jest zagrożeniem dla większej
          efektywności zespołu i może stanowić problem
        </div>
        <div className="success">
          <strong>zielonym</strong> - ta dysfunkcja prawdopodobnie nie jest problemem zespołu
        </div>
      </Card>
      <Button big onClick={onNext}>
        Zakończ test
      </Button>
    </AnimatedElement>
  )
}

export default TeamDysfunctionsVotingExercise
