import React from "react"
import { shuffle, defaultTo } from "lodash"

import ExerciseComponent from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import InstructionCard from "components/InstructionCard/InstructionCard"
import MatchCategories from "./subcomponents/MatchCategories"
import MatchAnswers from "./subcomponents/MatchAnswers"

import "./MatchExercise.scss"
import Sounds from "lib/Sounds/Sounds"
import AnimationCorrectExplosion from "animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion"
import AnimationIncorrectExplosion from "animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion"
import FeedbackCard from "components/FeedbackCard/FeedbackCard"
import { DEFAULT_STATES } from "base/ExerciseComponent"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import CONFIG from "config"
import { INSTRUCTION_STEP_TYPES } from "../../base/subcomponents"
import exerciseImg from "./img/exercise.jpg"
import exerciseVerticalImg from "./img/exercise-vertical.jpg"
import { withTranslation } from "react-i18next"

const POINTS_PER_ANSWER = 2
const DEFAULT_TIME_LIMIT_S = 60

const TIME_TO_READ_QUESTION_S = 3

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  ANSWERING: 2,
  SHOWING_FEEDBACK: 3,
  QUESTION_FINISHED: 4,
  QUESTION_CHANGING: 5,
}

class MatchExercise extends ExerciseComponent {
  static exerciseClass = "MatchExercise"
  questions = []
  currentQuestionIndex = 0

  initInstructions(props) {
    const { t, parameters } = props
    const { hidePoints } = parameters

    this.instruction = t("game_instruction")
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: t("instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: hidePoints
          ? t("instruction_hidden_points")
          : t("instruction_points"),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    }
  }

  static maxPoints(questions) {
    let points = 0

    for (let question of questions) {
      points += question.answers.length * POINTS_PER_ANSWER
    }

    return points
  }

  constructor(props) {
    super(props)

    const { questions, parameters } = this.props

    this.questions = questions
    const question = questions[0]

    this.state = {
      ...this.state,

      question,
      answers: this.prepareAnswers(question.answers),

      draggedPosition: undefined,
      draggedAnswer: undefined,
      feedback: {
        position: undefined,
        correct: undefined,
        message: "",
      },
    }

    this.maxPoints = MatchExercise.maxPoints(this.props.questions)
    this.timePerQuestionSeconds = defaultTo(parameters.timePerQuestionSeconds, DEFAULT_TIME_LIMIT_S)
    this.showQuestionCount = defaultTo(parameters.showQuestionCount, false)
  }

  prepareAnswers = (answers) => {
    return shuffle(answers).map((answer, index) => ({
      index,
      id: answer.id,
      content: answer.content,
      categoryId: answer.parameters.category,
      active: true,
      correctFeedback: answer.parameters.correctFeedback,
      incorrectFeedback: answer.parameters.incorrectFeedback,
    }))
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.ANSWERING],
        },
        questions,
        parameters
      ),
    ]
  }

  renderExercise(state, props) {
    const { t } = props
    const { question, answers, draggedPosition, feedback } = state

    return (
      <>
        <InstructionCard
          visible={
            !!question.content &&
            this.inStates([STATES.QUESTION_APPEARING, STATES.ANSWERING, STATES.SHOWING_FEEDBACK])
          }
          countType={this.showQuestionCount ? t("common:question") : undefined}
          countCurrent={this.currentQuestionIndex + 1}
          countMax={this.questions.length}
          mainText={question.content}
          markdown
          small
        />
        <AnimatedElement visible={this.inStates([STATES.ANSWERING, STATES.SHOWING_FEEDBACK])}>
          <MatchCategories
            isQuestionShown={!!question.content}
            categories={{
              1: question.parameters["category1"],
              2: question.parameters["category2"],
              3: question.parameters["category3"],
              4: question.parameters["category4"],
            }}
            draggedPosition={draggedPosition}
            onCategoryHit={this.categorySelected}
          />
          <MatchAnswers
            small={!!question.parameters["category3"]}
            answers={answers}
            setDraggedPosition={this.setDraggedPosition}
            disabled={this.inState(STATES.SHOWING_FEEDBACK)}
          />

          <AnimationCorrectExplosion
            fixed
            visible={this.inState(STATES.SHOWING_FEEDBACK) && feedback.correct}
            position={feedback.position}
          />
          <AnimationIncorrectExplosion
            fixed
            visible={this.inState(STATES.SHOWING_FEEDBACK) && !feedback.correct}
            position={feedback.position}
          />
          <FeedbackCard
            visible={this.inState(STATES.SHOWING_FEEDBACK)}
            content={feedback.message}
            successful={feedback.correct}
            onFinished={this.closeFeedback}
            useDefaultFeedback={false}
          />
        </AnimatedElement>
      </>
    )
  }

  startGame = () => {
    super._questionAppeared(this.state.question)
    this.setCurrentStateSequence(
      [STATES.QUESTION_APPEARING, STATES.ANSWERING],
      TIME_TO_READ_QUESTION_S * 1000
    )
  }

  isClockRunning = () => {
    return this.inState(STATES.ANSWERING)
  }

  setDraggedPosition = (draggedPosition, draggedAnswer) => {
    this.setState({
      draggedPosition,
      draggedAnswer,
    })
  }

  categorySelected = (categoryId) => {
    this.setState(
      (state) => {
        const answers = [...state.answers]
        const { draggedAnswer, draggedPosition, points } = state
        let correct, message, pointsChange

        answers[draggedAnswer.index].active = false

        if (draggedAnswer.categoryId === categoryId) {
          Sounds.success.play()
          pointsChange = POINTS_PER_ANSWER
          correct = true
          message = draggedAnswer.correctFeedback
        } else {
          Sounds.error.play()
          pointsChange = 0
          correct = false
          message = draggedAnswer.incorrectFeedback
        }

        const feedback = {
          position: draggedPosition,
          message,
          correct,
        }

        super._answerChosen(draggedAnswer, { correct })

        return {
          feedback,
          draggedAnswer: undefined,
          draggedPosition: undefined,

          answers,
          points: points + pointsChange,
        }
      },
      () => {
        this.setCurrentState(STATES.SHOWING_FEEDBACK)
      }
    )
  }

  closeFeedback = () => {
    this.setCurrentState(STATES.ANSWERING, this.checkIfQuestionFinished)
  }

  checkIfQuestionFinished = () => {
    const { answers } = this.state
    let finished = true

    for (const answer of answers) {
      if (answer.active) {
        finished = false
        break
      }
    }

    if (finished) {
      this.questionFinished()
    }
  }

  timeRanOut = () => {
    this.questionFinished()
  }

  questionFinished = () => {
    if (this.currentQuestionIndex + 1 >= this.questions.length) {
      this.setCurrentState(STATES.FINISHING)
    } else {
      this.setCurrentState(STATES.QUESTION_FINISHED, this.showNextQuestion, 500)
    }
  }

  showNextQuestion = () => {
    this.currentQuestionIndex++
    const question = this.questions[this.currentQuestionIndex]

    this.setState(
      {
        question,
        answers: this.prepareAnswers(question.answers),
      },
      () => {
        super._questionAppeared(this.state.question)
        this.setCurrentStateSequence(
          [STATES.QUESTION_CHANGING, STATES.QUESTION_APPEARING],
          CONFIG.ANIMATION_SPEED_MS,
          () => {
            this.setCurrentStateDelayed(STATES.ANSWERING, TIME_TO_READ_QUESTION_S * 1000)
          }
        )
      }
    )
  }
}

export default withTranslation(["exercises/match", "common"])(MatchExercise)
