import {ActionCreators} from "../../actions"

const T = {
  SECOND_PASSED: "EXERCISE__TIME__SECOND_PASSED",
  START: "EXERCISE__TIME__START",
  PAUSE: "EXERCISE__TIME__PAUSE",
  RESET: "EXERCISE__TIME__RESET"
}
const root = (s) => s.exercise.time

const ExerciseTimeActions = {
  T,
  S: {
    AVAILABLE: state => root(state).available,
    PER_QUESTION: state => root(state).perQuestion,
    IS_RUNNING: state => root(state).isRunning,
    CURRENT: state => root(state).current,
  },

  setMaxPoints: ActionCreators.singleParameter(T.SET_MAX_POINTS, "max"),
  secondPassed: ActionCreators.noParameters(T.SECOND_PASSED),
  start: ActionCreators.noParameters(T.START),
  pause: ActionCreators.noParameters(T.PAUSE),
  reset: ActionCreators.noParameters(T.RESET),
}

export default ExerciseTimeActions