import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import Element from "../../components/Element"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import FullStage from "../../components/FullStage"
import { Group, Layer } from "react-konva"
import TrueFalseAnswer, { TRUE_FALSE_ANSWER_STATE } from "./subcomponents/TrueFalseAnswer"
import TrueFalseButtons from "./subcomponents/TrueFalseButtons"
import useCurrentState from "../../hooks/useCurrentState"
import { cloneDeep } from "lodash"

import "./TrueFalseExercise.scss"
import Card from "components/Card/Card"
import Button from "components/Button"
import { useTranslation } from "react-i18next"
import { usePointsState } from "../../state/exercise/$points"
import Sounds from "../../lib/Sounds/Sounds"
import { useTimeoutFn } from "react-use"
import COLORS from "../../enums/colors"
import ReactMarkdown from "react-markdown"
import { UnmountClosed } from "react-collapse/lib/UnmountClosed"

const SUMMARY_TEXTS = [
  [
    125,
    "**Gratulacje!** Wygląda na to, że Wasza organizacja jest pionierem trendów w HR. Dzięki temu HR skutecznie wspiera biznes.\n\nZastanów się co jeszcze możesz zrobić w HR, aby podnieść efektywność biznesową i zwiększyć satysfakcję pracowników. Trzymaj rękę na pulsie, aby być na bieżąco! Planuj strategiczne zmiany z wyprzedzeniem!",
  ],
  [
    95,
    "**Brawo!** Wygląda na to, że Wasza organizacja jest zaawansowana w kwestii narzędzi i procesów HR i dzięki temu bardzo dobrze wspiera biznes.\n\nZastanów się, które z najnowszych trendów HR 3.0 warto wdrożyć, aby ponieść efektywność biznesową Waszej organizacji i zwiększyć zaangażowanie Waszych pracowników.",
  ],
  [
    60,
    "Wygląda na to, że w HR Waszej organizacji sporo jest do zrobienia w kwestii procesów i narzędzi HR.\n\nObecnie HR nie wykorzystuje w pełni swojego potencjału do wspierania biznesu. Nie wykluczone, że pływa to na efektywność biznesową Waszej firmy. Zastanów się, które z najnowszych trendów HR 3.0 warto szybko wdrożyć, aby Wasza firma mogła szybciej rozwijać się i lepiej odpowiadać na potrzeby pracowników.",
  ],
  [
    0,
    "Wygląda na to, że czas najwyższy zainwestować w narzędzia i procesy HR, aby wyraźnie zwiększyć efektywność Waszej organizacji.\n\nDalszy rozwój firmy może być spowolniony przez tę sytuację. Zastanów się jakie rozwiązania warto wdrożyć w pierwszej kolejności tak aby HR mógł mocnej wspierać rozwój biznesu i lepiej odpowiadać na potrzeby pracowników.",
  ],
]

const CATEGORY_PARAMETERS_DEFAULTS = {
  enabled: false,
  title: "",
  splitCategories: false,
}

const TrueFalseAnswersCategories = ({ categories, categoriesCount, parameters, finish }) => {
  const { t } = useTranslation("common")
  const [summaryText, setSummaryText] = useState("")
  const [summary, setSummary] = useState(
    Object.entries(categoriesCount)
      .sort(([nameA, countA], [nameB, countB]) => {
        return nameA.localeCompare(nameB)
      })
      .map(([category, answersCount]) => ({ category, percentage: 0, answersCount }))
  )
  const summaryTextRef = useRef("")

  const { title, splitCategories } = parameters

  useTimeoutFn(() => {
    const allAnswersCount = summary.reduce(
      (currentValue, currentElement) => currentValue + (categories[currentElement.category] ?? 0),
      0
    )

    let newValue = 0
    let newSummaryText

    setSummary(
      summary.map(({ category, answersCount }) => {
        let percentage =
          (categories[category] ?? 0) / (splitCategories ? answersCount : allAnswersCount)
        if (percentage > 0) {
          if (category === "HR 2.0") {
            newValue += Math.round(100 * percentage)
          } else if (category === "HR 3.0") {
            newValue += Math.round(200 * percentage)
          }

          percentage = `${(percentage * 100).toFixed(0)}%`
        }

        return {
          category,
          percentage,
          answersCount,
        }
      })
    )

    for (let i = 0; i < SUMMARY_TEXTS.length; i++) {
      const [threshold, text] = SUMMARY_TEXTS[i]

      if (newValue >= threshold) {
        newSummaryText = text
        break
      }
    }
    summaryTextRef.current = newSummaryText
  }, 500)

  useTimeoutFn(() => {
    setSummaryText(summaryTextRef.current)
  }, 2000)

  return (
    <div className="TrueFalseAnswersCategories">
      <Card>
        <h1>{title}</h1>
        <table className="categories">
          <tbody>
            {summary.map(({ category, percentage }) => (
              <tr key={category}>
                <td>{category}</td>
                <td>
                  <div
                    className="count"
                    style={{ width: percentage, color: !!percentage ? COLORS.MAIN : COLORS.BRIGHT }}
                  >
                    {!!percentage && <>{percentage}</>}
                  </div>
                  {!percentage && <div>0%</div>}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <UnmountClosed isOpened={!splitCategories && !!summaryText}>
          <div className="summary-text">
            <ReactMarkdown source={summaryText} />
          </div>
        </UnmountClosed>
      </Card>
      <Button className="md" onClick={finish}>
        {t("continue")}
      </Button>
    </div>
  )
}

const TrueFalseExercise = ({
  state,
  inAnswersState,
  question,
  answers,
  parameters,
  answerChosen,
  resetTimestamp,
  finish,
}) => {
  const {
    state: answer_state,
    changeStatesInSequence: changeAnswerStatesInSequence,
  } = useCurrentState(TRUE_FALSE_ANSWER_STATE, TRUE_FALSE_ANSWER_STATE.ACTIVE)
  const [answer_index, setAnswerIndex] = useState(0)
  const [answer_correct, setAnswerCorrect] = useState(true)
  const [answerCategories, setAnswerCategories] = useState({ summary: {}, count: {} })
  const current_answer = useMemo(() => answers[answer_index], [answers, answer_index])
  const stage_ref = useRef()
  const answerCategoriesRef = useRef({})
  const answerCategoriesCountRef = useRef({})
  const feedbackAvailableRef = useRef(false)
  const {
    parameters: { trueName, falseName },
  } = question
  const { categories } = parameters
  const { max } = usePointsState()
  const [categoriesParameters] = useState({
    ...CATEGORY_PARAMETERS_DEFAULTS,
    ...categories,
  })

  const nextAnswer = useCallback(() => {
    if (answer_index + 1 < answers.length) {
      setAnswerIndex(answer_index + 1)
      changeAnswerStatesInSequence(
        [TRUE_FALSE_ANSWER_STATE.APPEARING, TRUE_FALSE_ANSWER_STATE.ACTIVE],
        600,
        resetTimestamp
      )
    }
  }, [answers, answer_index, changeAnswerStatesInSequence, resetTimestamp])

  const innerAnswerChosen = useCallback(
    (is_correct) => {
      let answer = cloneDeep(current_answer)
      const category = answer.parameters.category

      if (category) {
        answerCategoriesCountRef.current[category] =
          (answerCategoriesCountRef.current[category] ?? 0) + 1
        if (is_correct && !!category) {
          answerCategoriesRef.current[category] = (answerCategoriesRef.current[category] ?? 0) + 1
        }
      }

      setAnswerCorrect(is_correct)
      answer.correct = is_correct
      answer.parameters.feedback = is_correct
        ? answer.parameters.correctFeedback
        : answer.parameters.incorrectFeedback

      const sound = is_correct || max === 0 ? Sounds.success : Sounds.error
      sound.play()

      answerChosen(answer)
      feedbackAvailableRef.current = !!answer.parameters.feedback

      if (feedbackAvailableRef.current) {
        changeAnswerStatesInSequence(
          [TRUE_FALSE_ANSWER_STATE.INACTIVE, TRUE_FALSE_ANSWER_STATE.DISAPPEARING],
          500
        )
      } else {
        changeAnswerStatesInSequence(
          [TRUE_FALSE_ANSWER_STATE.INACTIVE, TRUE_FALSE_ANSWER_STATE.DISAPPEARING],
          500,
          nextAnswer,
          1000
        )
      }
    },
    [current_answer, max, answerChosen, changeAnswerStatesInSequence, nextAnswer]
  )

  useEffect(() => {
    if (state === AS.ACTIVE) {
      resetTimestamp()
    }
  }, [state, resetTimestamp])

  useEffect(() => {
    if (
      state === AS.ACTIVE &&
      answer_state === TRUE_FALSE_ANSWER_STATE.DISAPPEARING &&
      feedbackAvailableRef.current
    ) {
      nextAnswer()
    } else if (state === AS.FINISHING) {
      if (
        categoriesParameters.enabled &&
        Object.keys(answerCategoriesCountRef.current).length > 0
      ) {
        setAnswerCategories({
          summary: answerCategoriesRef.current,
          count: answerCategoriesCountRef.current,
        })
      } else {
        finish()
      }
    }
  }, [state, answer_state, categoriesParameters.enabled, nextAnswer, finish])

  return (
    <div className="TrueFalseExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE, AS.INACTIVE])}>
        <FullStage stage_ref={stage_ref}>
          <Layer>
            <Group>
              <TrueFalseAnswer
                correct={answer_correct}
                state={answer_state}
                answer={current_answer}
              />
              <TrueFalseButtons
                trueName={trueName}
                falseName={falseName}
                answer={current_answer}
                choose={innerAnswerChosen}
                active={inAnswersState(AS.ACTIVE)}
              />
            </Group>
          </Layer>
        </FullStage>
      </Element>
      <Element
        active={
          categoriesParameters.enabled &&
          // Object.keys(answerCategories.summary).length > 0 &&
          inAnswersState([AS.FINISHING])
        }
      >
        <TrueFalseAnswersCategories
          categories={answerCategories.summary}
          categoriesCount={answerCategories.count}
          parameters={categoriesParameters}
          finish={finish}
        />
      </Element>
    </div>
  )
}

export default TrueFalseExercise
