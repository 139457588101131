import React, { Component } from "react"
import PropTypes from "prop-types"
import axios from "axios/index"
import classNames from "classnames"

import "./css/SendResultsScreen.scss"
import Card from "components/Card/Card"
import Animation from "components/Animation"
import Button from "components/Button/Button"
import { Translation } from "react-i18next"
import Element from "../components/Element"

const ANIMATION_SPEED = 1000

export default class SendResultsScreen extends Component {
  timeout

  static propTypes = {
    serverAddress: PropTypes.string,

    exercisesPoints: PropTypes.array,
    otherContent: PropTypes.object,
    sessionToken: PropTypes.string,
    instanceName: PropTypes.string,

    goNextAction: PropTypes.func,
  }

  static defaultProps = {
    exercisesPoints: [],
    otherContent: {},
    events: {},

    goNextAction: () => {},
  }

  constructor(props) {
    super(props)

    this.state = {
      sending: true,
      error: false,
      errorMessage: "",
      timeout: null,

      visible: true,
    }
  }

  componentDidMount() {
    this.startSending()
  }

  componentWillUnmount() {
    if (this.state.timeout) {
      clearTimeout(this.state.timeout)
    }
  }

  startSending = () => {
    if (!this.state.timeout) {
      let timeout = setTimeout(this._sendResults, 1000)

      this.setState({
        sending: true,
        error: false,
        timeout: timeout,
      })
    }
  }

  _sendResults = () => {
    axios(this._getConnectionOptions())
      .then((result) => {
        // SUCCESS
        this.setState({
          sending: false,
          error: false,
          timeout: null,
        })
        setTimeout(this.goNext, 500)
      })
      .catch((error) => {
        // ERROR
        this.setState({
          sending: false,
          error: true,
          errorMessage:
            "Wystąpił problem podczas przesyłania wyniku - upewnij się, że masz połączenie z Internetem. " +
            "Jeśli problem będzie się powtarzał - skontaktuj się z nami",
          timeout: null,
        })
      })
  }

  _getConnectionOptions = () => {
    let username = window.localStorage.getItem("username")
    let token = window.localStorage.getItem("token")
    let points = this._getPointsTotal()

    return {
      method: "POST",
      url: this.props.serverAddress + "send-results",
      data: {
        username: username,
        userToken: token,
        points: points.total,
        pointsMax: points.max,
        other: this.props.otherContent,
        events: this.props.events,
        sessionToken: this.props.sessionToken,
        instanceName: this.props.instanceName,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  }

  _getContentString = () => {
    let returnedString = ""
    for (let element of this.props.otherContent) {
      returnedString += "<p>" + element + "</p>"
    }

    return returnedString
  }

  _getPointsString = () => {
    let returnedString = ""
    let points = 0
    let maxPoints = 0
    for (let element of this.props.exercisesPoints) {
      returnedString +=
        "<p>" + element.name + ": " + element.points + " / " + element.maxPoints + "</p>"
      points += element.points
      maxPoints += element.maxPoints
    }
    returnedString += "<p><strong>Razem: </strong>" + points + " / " + maxPoints + "</p>"

    return returnedString
  }

  _getPointsTotal = () => {
    let total = 0
    let max = 0

    for (let element of this.props.exercisesPoints) {
      total += element.points
      max += element.maxPoints
    }

    return {
      total,
      max,
    }
  }

  startSendingButton = () => {
    this.startSending()
  }

  goNext = () => {
    if (this.state.visible) {
      this.setState({
        visible: false,
      })
      setTimeout(this.props.goNextAction, ANIMATION_SPEED)
    }
  }

  render() {
    return (
      <Element active={this.state.visible}>
        <Translation ns={"send_results"}>
          {(t) => (
            <div>
              <div className="SendResultsScreen">
                <Card className={classNames("message", { error: this.state.error })}>
                  <div className={"pure-u-1-1 content"}>
                    {this.state.sending && (
                      <h1>
                        <i className="fas fa-spinner fa-spin" /> {t("sending")}
                      </h1>
                    )}
                    {!this.state.sending && (
                      <span>
                        {!this.state.error && <h1>{t("resultsSent")}</h1>}
                        {
                          this.state.error && <h1>{t("sendError")}</h1>
                          // <h1>{this.state.errorMessage}</h1>
                        }
                      </span>
                    )}
                  </div>
                </Card>
              </div>
              <Animation type="fade" active={!this.state.sending && this.state.error}>
                <Button onClick={this.startSendingButton} big>
                  {t("tryAgain")}
                </Button>
              </Animation>
            </div>
          )}
        </Translation>
      </Element>
    )
  }
}
