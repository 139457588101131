import React, { useCallback, useEffect, useRef, useState } from "react"
import Konva from "konva"
import { Circle, Group } from "react-konva"
import { range } from "lodash"

import useHVPosition from "../../hooks/screen/useHVPosition"
import COLORS from "../../enums/colors"

const Explosion = ({ active, count, duration = 1000 }) => {
  const [wasAnimated, setWasAnimated] = useState(false)
  const { width: maxRadius, height: spread } = useHVPosition({
    width: [0.02, 0.03],
    height: [0.2, 0.12],
  })

  const circleRef = useRef({})

  const animate = useCallback(() => {
    Object.values(circleRef.current).forEach((node) => {
      new Konva.Tween({
        node,
        duration: 0.8,
        easing: Konva.Easings.Linear,

        offsetX: spread,
      }).play()

      new Konva.Tween({
        node,
        duration: 0.8,
        easing: Konva.Easings.StrongEaseIn,

        offsetY: spread,
      }).play()

      new Konva.Tween({
        node,
        duration: 0.5,
        easing: Konva.Easings.EaseIn,

        radius: maxRadius,
        onFinish: () => {
          new Konva.Tween({
            node,
            duration: 0.5,
            easing: Konva.Easings.EaseOut,

            radius: 0,
          }).play()
        },
      }).play()
    })
  }, [spread, maxRadius])

  useEffect(() => {
    if (active && !wasAnimated) {
      animate()
      setWasAnimated(true)
    }
  }, [active, wasAnimated, animate])

  return (
    <Group>
      {range(count).map((id) => (
        <Circle
          key={id}
          ref={(n) => (circleRef.current[id] = n)}
          offsetX={0}
          offsetY={0}
          radius={0}
          rotation={(id / count) * 360}
          fill={COLORS.BRIGHT}
        />
      ))}
    </Group>
  )
}

export default Explosion
