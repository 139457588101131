import { MODULE_LIST } from "./ModuleList"

export default class ModuleFactory {
  static getModuleForType(moduleType, moduleParameters, exerciseParameters, questions) {
    const moduleClass = MODULE_LIST[moduleType]

    if (moduleClass === undefined) {
      console.warn(`[ModuleFactory] Unknown parameter type for module: ${moduleType}.`)
    } else {
      return new moduleClass(moduleParameters, exerciseParameters, questions)
    }
  }
}
