import React, { useEffect, useRef, useState } from "react"

import { ReactComponent as TruckImg } from "../img/truck.svg"

import "./Truck.scss"
import clsx from "clsx"
import { isPointInRectangle } from "../../../lib/Geometry"
import AnimationCorrectExplosion from "../../../animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion"
import AnimationIncorrectExplosion from "../../../animations/AnimationIncorrectExplosion/AnimationIncorrectExplosion"

export const Truck = ({
  isDragging,
  draggedAnswer,
  draggedPosition,
  onAnswerChosen,
  isDrivingOff,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const [feedback, setFeedback] = useState({ position: [0, 0], correct: true })
  const [showFeedback, setShowFeedback] = useState(false)
  const imgRef = useRef(null)

  useEffect(() => {
    if (draggedPosition[0] !== 0 && draggedPosition[1] !== 0) {
      setFeedback({ position: draggedPosition, correct: draggedAnswer?.correct })
    }
  }, [draggedPosition, draggedAnswer])

  useEffect(() => {
    const img = imgRef.current
    const isHovering = img && isPointInRectangle(draggedPosition, img.getBoundingClientRect())

    setIsHovered(isDragging && isHovering)

    if (!isDragging && isHovering) {
      onAnswerChosen(draggedAnswer)
      setShowFeedback(true)
    }
  }, [isDragging, draggedPosition, draggedAnswer, onAnswerChosen])

  useEffect(() => {
    if (showFeedback) {
      setTimeout(() => setShowFeedback(false), 1000)
    }
  }, [showFeedback])

  return (
    <div
      className={clsx("Truck", {
        entering: !isDrivingOff,
        leaving: isDrivingOff,
        hover: isHovered,
      })}
    >
      <div className="truck-door" />
      <div className="truck-door-2" />
      <TruckImg ref={imgRef} className="truck-image" />
      <AnimationCorrectExplosion
        fixed
        visible={showFeedback && feedback.correct}
        position={feedback.position}
      />
      <AnimationIncorrectExplosion
        fixed
        visible={showFeedback && !feedback.correct}
        position={feedback.position}
      />
    </div>
  )
}
