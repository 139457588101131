import {WIDTH_EMS, WIDTH_EMS_VERTICAL} from "app/Sprint";


export default class PlayArea {
  static isVertical() {
    return window.innerHeight > window.innerWidth;
  }

  static isHorizontal() {
    return window.innerHeight < window.innerWidth;
  }

  static widthInEms() {
    if (this.isVertical()) {
      return WIDTH_EMS_VERTICAL;
    } else {
      return WIDTH_EMS;
    }
  }

  static heightInEms() {
    const width = PlayArea.widthInEms();

    if (this.isVertical()) {
      return width * 4 / 3;
    } else {
      return width * 3 / 4;
    }
  }
}