import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames'

import Sounds from 'lib/Sounds'

import './css/Card.scss';

class Card extends Component {
  static propTypes = {
    'saveDOM': PropTypes.bool,
    'onClick': PropTypes.func,
    'classes': PropTypes.string,
    'className': PropTypes.string,
    'visible': PropTypes.bool,
    'hidden': PropTypes.bool,

    'type': PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  };

  static TYPES = {
    accent: 'accent',
    bordered: 'bordered',
  }

  DOM = null;

  render() {
    let style = this.props.style ? _.clone(this.props.style) : {};
    if (this.props.hidden) {
      style['opacity'] = 0;
    }

    let type = this.props.type
    if (Array.isArray(type)) {
      type = type.join(' ')
    }

    return (
      <div className={"OldCard"
              + (this.props.classes ? ' ' + this.props.classes : '')
              + (this.props.className ? ' ' + this.props.className : '')
              + (this.props.hidden ? ' hide' : '')
              + (this.props.type ? ` ${type}` : '')
      }
           onClick={this.props.onClick}
           style={style}
           ref={this.props.saveDOM ? (element) => {this.DOM = element} : null}
      >
        { this.props.children }
      </div>
    );
  }
}

export class ButtonCard extends Component {
  static types = {
    large: 'large'
  }
  
  static propTypes = {
    'type': PropTypes.string,
    
    'onClick': PropTypes.func,
  };

  render() {
    return (
      <Card className={classNames('button', this.props.type)} onClick={this.clicked}>
        <p>
          { this.props.children }
        </p>
      </Card>
    )
  }

  clicked = () => {
    Sounds.click.play()
    this.props.onClick()
  }
}

export default Card;
