import StatsModule from "@exercises/modules/StatsModule"
import TYPES from "./_types"

export default class ChosenAnswerStatsModule extends StatsModule {
  answerChosen = (answer, options = {}) => {
    const { correct, chosenAnswerOther, other } = options
    const reactionTimestamp = Date.now()

    const startTimestamp = this.timestamps[answer.id] ?? this.timestamp

    super.answerChosen(answer)

    let data = {
      answerId: answer.id,
      questionId: this.currentQuestion.id,
      reactionTime: reactionTimestamp - startTimestamp,
    }

    if (correct !== undefined) {
      data["correct"] = correct
    } else {
      data["correct"] = answer.correct
    }

    if (chosenAnswerOther !== undefined) {
      data["other"] = chosenAnswerOther
    }

    if (other !== undefined) {
      data["other"] = other
    }

    this.resetTimestamp()

    const newEvent = this.createEvent(TYPES.ANSWER_CHOSEN, data)

    this.addEvent(newEvent)
  }
}
