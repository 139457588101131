import Color from "color"
import s from "colors.module.scss"

const BASE_COLORS = {
  MAIN: s.main,
  BRIGHT: s.bright,
  CLICKABLE: s.clickable,

  SUCCESS: s.success,
  ERROR: s.error,

  WHITE: "#FFFFFF",
  BLACK: "#000000",
}

const COLORS = {
  ...BASE_COLORS,
  CLICKABLE_HOVER: Color(BASE_COLORS.CLICKABLE).lighten(0.2).hex(),

  FONT: s.font,
}

export default COLORS
