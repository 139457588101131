import React, { useCallback, useEffect, useMemo, useRef } from "react"

import PointsBar from "components/PointsBar"
import ExerciseHints from "../base/subcomponents/ExerciseHints"
import useCurrentState from "../hooks/useCurrentState"
import ExerciseInstructions from "../base/subcomponents"
import Element from "../components/Element"
import InstructionCard from "../components/InstructionCard/InstructionCard"
import FeedbackCard from "../components/FeedbackCard"
import {
  getExerciseComponent,
  useExerciseState,
  useExerciseTranslation,
} from "state/exercise/$exercise"
import { useAnswersState } from "../state/exercise/$answers"
import getConfig from "./configs"
import { useTime } from "../state/exercise/$time"
import { usePoints } from "../state/exercise/$points"
import { useQuestions } from "../state/exercise/$questions"
import { useSprintState } from "../state/$sprint"
import ChosenAnswerStatsModule from "./modules/stats/ChosenAnswerStatsModule"
import { useMount, useUnmount } from "react-use"

const S = {
  INITIALISING: "EXERCISE__INITIALISING",
  INSTRUCTIONS_SHOWING: "EXERCISE__INSTRUCTIONS_SHOWING",
  STARTING: "EXERCISE__EXERCISE_STARTING",
  STARTED: "EXERCISE__EXERCISE_STARTED",
  QUESTION_APPEARING: "EXERCISE__QUESTION_APPEARING",
  QUESTION_ANSWERING: "EXERCISE__QUESTION_ANSWERING",
  QUESTION_SHOWING_FEEDBACK: "EXERCISE__QUESTION_SHOWING_FEEDBACK",
  QUESTION_DISAPPEARING: "EXERCISE__QUESTION_DISAPPEARING",
  FINISHING: "EXERCISE__FINISHING",
  FINISHED: "EXERCISE__FINISHED",
}

S.$EXERCISE_RUNNING = [
  S.STARTED,
  S.QUESTION_APPEARING,
  S.QUESTION_ANSWERING,
  S.QUESTION_SHOWING_FEEDBACK,
  S.QUESTION_DISAPPEARING,
]

export const ANSWERS_STATES = {
  HIDDEN: "ANSWERS__HIDDEN",
  APPEARING: "ANSWERS__APPEARING",
  ACTIVE: "ANSWERS__ACTIVE",
  INACTIVE: "ANSWERS__INACTIVE",
  DISAPPEARING: "ANSWERS__DISAPPEARING",
  FINISHING: "ANSWERS__FINISHING",
  FINISHED: "ANSWERS__FINISHED",
}

const AS = ANSWERS_STATES

const Exercise = ({ exercise: config, onFinish }) => {
  const loaded_config = useMemo(() => getConfig(config), [config])

  const {
    images,
    parameters: {
      hasPostExerciseScreen,
      customInstructions,
      customName,
      customInstruction,
      showPointsBar,
      instructions: { InstructionsComponent },
      answers: { feedback },
    },
  } = loaded_config

  const ExerciseComponent = getExerciseComponent(loaded_config)
  const { t, initialised, instructions, hints } = useExerciseTranslation(loaded_config)
  const { setSides, resetSides } = useSprintState()
  const { resetState } = useExerciseState()
  const {
    current: current_question,
    count: { all: all_question_count, current: current_question_count },
    answers: { current: current_answers, all_tries_finished, answerChosen },

    nextQuestion,
    resetAQState,
  } = useQuestions(loaded_config)
  const {
    enabled: showTime,
    readable: readableTime,
    is_running,
    is_running_out,
    ran_out,
    start: startTime,
    pause: pauseTime,
    reset: resetTime,
  } = useTime(loaded_config)
  const { enabled: showPoints, current: points, max: maxPoints } = usePoints(loaded_config)

  const last_answer = useAnswersState((s) => s.last_answer)

  const { changeState, changeStatesInSequence, inState } = useCurrentState(S, S.INITIALISING)
  const {
    state: answers_state,
    inState: inAnswersState,
    changeState: changeAnswersState,
    changeStatesInSequence: changeAnswersStateInSequence,
  } = useCurrentState(AS, AS.HIDDEN)

  const chosenAnswerStats = useRef(new ChosenAnswerStatsModule())

  /// /// /// /// ///
  ///
  ///  On mount
  ///
  /// /// /// /// ///
  useMount(() => {
    resetState({ config: loaded_config })
  })

  useUnmount(() => {
    resetSides()
  })

  /// /// /// /// ///
  ///
  ///  Functions
  ///
  /// /// /// /// ///

  const finishExercise = useCallback(() => {
    changeStatesInSequence([S.FINISHING, S.FINISHED], 600, () => {
      resetAQState()
      setTimeout(() => {
        onFinish({ points, events: chosenAnswerStats.current.getEvents() })
      })
    })
  }, [points, onFinish, resetAQState, changeStatesInSequence])

  const showNextQuestion = useCallback(
    (isFirstQuestion = false) => {
      let wasQuestionChanged = true
      if (!isFirstQuestion) {
        resetTime()
        wasQuestionChanged = nextQuestion()
      }

      if (wasQuestionChanged) {
        changeStatesInSequence([S.QUESTION_APPEARING, S.QUESTION_ANSWERING], 600, startTime)
        setTimeout(() => changeAnswersStateInSequence([AS.APPEARING, AS.ACTIVE], 600), 600)
      } else if (hasPostExerciseScreen) {
        setTimeout(() => changeAnswersStateInSequence([AS.FINISHING]), 600)
      } else {
        finishExercise()
      }
    },
    [
      hasPostExerciseScreen,

      nextQuestion,
      changeStatesInSequence,
      changeAnswersStateInSequence,
      finishExercise,

      startTime,
      resetTime,
    ]
  )

  const startExercise = useCallback(() => {
    const { sides } = loaded_config.parameters
    setSides(sides)

    changeStatesInSequence([S.STARTING, S.STARTED], 600, () => showNextQuestion(true))
  }, [loaded_config.parameters, changeStatesInSequence, showNextQuestion, setSides])

  const hideQuestion = useCallback(() => {
    changeAnswersState(AS.DISAPPEARING)
    changeState(S.QUESTION_DISAPPEARING, showNextQuestion, 1200)
  }, [changeState, changeAnswersState, showNextQuestion])

  const hideFeedback = useCallback(() => {
    if (ran_out || all_tries_finished) {
      hideQuestion()
    } else {
      startTime()
      changeState(S.QUESTION_ANSWERING)
      changeAnswersState(AS.ACTIVE)
    }
  }, [ran_out, all_tries_finished, startTime, hideQuestion, changeState, changeAnswersState])

  const innerAnswerChosen = useCallback(
    (answer, options) => {
      if (inState(S.QUESTION_ANSWERING)) {
        chosenAnswerStats.current.answerChosen(answer, options)
        answerChosen(answer)
      }
    },
    [answerChosen, inState]
  )

  /// /// /// /// ///
  ///
  ///  Effects
  ///
  /// /// /// /// ///

  useEffect(() => {
    const { showInstructions } = loaded_config.parameters

    if (initialised && inState(S.INITIALISING)) {
      if (Object.values(instructions).length > 0 && showInstructions)
        changeState(S.INSTRUCTIONS_SHOWING)
      else startExercise()
    }
    // eslint-disable-next-line
  }, [initialised, instructions, changeState, startExercise])

  useEffect(() => {
    if (last_answer.id) {
      if (all_tries_finished && last_answer.correct && current_question.parameters.feedback) {
        last_answer.feedback = current_question.parameters.feedback
      }

      pauseTime()
      if (!inState([S.FINISHING, S.FINISHED])) {
        changeState(S.QUESTION_SHOWING_FEEDBACK)
        changeAnswersState(AS.INACTIVE)
      }
    }
    // eslint-disable-next-line
  }, [last_answer])

  useEffect(() => {
    if (ran_out && inState(S.QUESTION_ANSWERING)) {
      hideQuestion()
    }
  }, [ran_out, hideQuestion, inState])

  useEffect(() => {
    chosenAnswerStats.current.questionAppeared(current_question)
  }, [current_question])

  return (
    <>
      {!inState(S.FINISHED) && (
        <Element className="Exercise" active={!inState([S.INITIALISING, S.FINISHED])}>
          {/**
              Exercise instructions
           */}
          <Element active={inState(S.INSTRUCTIONS_SHOWING)}>
            {InstructionsComponent && (
              <InstructionsComponent
                t={t}
                parameters={loaded_config.parameters}
                onFinish={startExercise}
              />
            )}
            {!InstructionsComponent && (
              <ExerciseInstructions
                name={customName ?? t("name")}
                steps={customInstructions ?? instructions}
                imageHorizontal={images.horizontal}
                imageVertical={images.vertical}
                onFinish={startExercise}
              />
            )}
          </Element>
          {/**
           Exercise running
           */}
          <Element active={inState(S.$EXERCISE_RUNNING)}>
            {showPointsBar && (
              <PointsBar
                t={t}
                visible={inState(S.$EXERCISE_RUNNING) && !inAnswersState(AS.FINISHING)}
                showPoints={showPoints}
                currentPoints={points}
                maxPoints={maxPoints}
                showTime={showTime}
                readableTime={readableTime}
                isTimeRunning={is_running}
                isTimeRunningOut={is_running_out}
                instruction={customInstruction}
              />
            )}
            {hints.length > 0 && <ExerciseHints hints={hints} />}
            {loaded_config.parameters.questions.show && (
              <InstructionCard
                visible={inState([
                  S.QUESTION_APPEARING,
                  S.QUESTION_ANSWERING,
                  S.QUESTION_SHOWING_FEEDBACK,
                ])}
                countType={t("common:question")}
                countCurrent={current_question_count}
                countMax={all_question_count}
                mainText={current_question.content}
              />
            )}
            <FeedbackCard
              visible={inState(S.QUESTION_SHOWING_FEEDBACK)}
              successful={last_answer.correct}
              content={last_answer.feedback}
              onFinished={hideFeedback}
              useDefaultFeedback={false}
              delayAutoContinue={!feedback ? 0 : undefined}
            />
            <ExerciseComponent
              t={t}
              state={answers_state}
              inAnswersState={inAnswersState}
              question={current_question}
              isLastQuestion={current_question_count === all_question_count}
              answers={current_answers}
              parameters={loaded_config.parameters}
              answerChosen={innerAnswerChosen}
              resetTimestamp={chosenAnswerStats.current.resetTimestamp}
              finish={finishExercise}
              nextQuestion={hideQuestion}
            />
          </Element>
        </Element>
      )}
    </>
  )
}

export default Exercise
