import React from "react"
import Draggable from "react-draggable"
import classNames from "classnames"

import Card from "components/Card/Card"

import "./CauldronAnswers.scss"
import ReactMarkdown from "react-markdown"

export default function CauldronAnswers({ answers, setDraggedPosition, disabled }) {
  const answerDraggedAction = (answer) => (event) => {
    let positionedElement = event
    if (event.changedTouches && event.changedTouches.length > 0) {
      positionedElement = event.changedTouches[0]
    } else if (event.touches && event.touches.length > 0) {
      positionedElement = event.touches[0]
    }

    const position = [positionedElement.clientX, positionedElement.clientY]

    setDraggedPosition(position, answer)
  }

  return (
    <div className="CauldronAnswers">
      {answers.map((answer, index) => {
        return (
          <Draggable
            handle=".answer"
            // defaultPosition={{x: 0, y: 0}}
            position={{ x: 0, y: 0 }}
            onStop={answerDraggedAction(answer)}
            disabled={!answer.active || disabled}
            key={index}
          >
            <Card
              className={classNames("answer", { disabled })}
              isOpaque={!answer.active}
              isDraggable
            >
              <ReactMarkdown source={answer.content} />
            </Card>
          </Draggable>
        )
      })}
    </div>
  )
}
