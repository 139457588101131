import React from "react"

import ExerciseComponent from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import InstructionCard from "components/InstructionCard/InstructionCard"
import Box from "./subcomponents/Box"
import PackageAnswers from "./subcomponents/PackageAnswers"

import Sounds from "lib/Sounds/Sounds"
import AnimationCorrectExplosion from "animations/AnimationCorrectExplosionNew/AnimationCorrectExplosion"
import { DEFAULT_STATES } from "base/ExerciseComponent"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import Button from "../../components/Button/Button"
import { withTranslation } from "react-i18next"

import "./PackageExercise.scss"
import { DEFAULT_ANIMATION_SPEED, SERVER_ADDRESS } from "app/Sprint"
import classNames from "classnames"
import Card from "../../components/Card/Card"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import { ReactComponent as StampImg } from "./img/stamp.svg"
import validate from "validate.js"
import axios from "axios"
import CONFIG from "../../config"
import FeedbackCard from "../../components/FeedbackCard";

const TIME_TO_READ_QUESTION_S = 3

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_APPEARING: 1,
  ANSWERING: 2,
  SHOWING_FEEDBACK: 3,
  QUESTION_FINISHING: 4,
  ENTERING_EMAIL: 5,
  SENDING: 6,
  SENT: 7,
}

const FORM_CONSTRAINTS = {
  email: {
    email: {
      message: "^Podaj prawidłowy adres e-mail.",
    },
  },
}

class PackageExercise extends ExerciseComponent {
  static exerciseClass = "PackageExercise"
  questions = []
  static hasPointsBar = false
  limit = 3
  type = null

  static maxPoints() {
    return 0
  }

  constructor(props) {
    super(props)

    const { questions, parameters } = this.props

    this.questions = questions
    const question = questions[0]

    this.limit = parameters.limit ?? this.limit
    this.type = parameters.type ?? null

    this.state = {
      ...this.state,

      question: {
        index: 0,
        current: question,
      },
      selectedAnswers: [],
      answers: this.prepareAnswers(question.answers),

      dragged: {
        position: undefined,
        answer: undefined,
        isDragging: false,
      },

      feedback: {
        message: "",
        position: undefined,
      },

      form: {
        email: "",
      },

      message: {
        content: "",
        type: "error",
      },
    }
  }

  prepareAnswers = (answers) => {
    return answers.map((answer, index) => ({
      index,
      id: answer.id,
      content: answer.content,
      correct: answer.correct,
      feedback: answer.parameters.feedback,
      active: true,
    }))
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.ANSWERING],
        },
        questions,
        parameters
      ),
    ]
  }

  renderExercise(state, props) {
    const { question, answers, selectedAnswers, dragged, feedback } = state
    const { t } = props

    const isLimitReached = selectedAnswers.length >= this.limit
    const isBoxEmpty = selectedAnswers.length === 0

    return (
      <>
        <InstructionCard
          visible={this.inStates([
            STATES.QUESTION_APPEARING,
            STATES.ANSWERING,
            STATES.SHOWING_FEEDBACK,
          ])}
          mainText={question.current.content}
          markdown
          small
        />
        <AnimatedElement visible={this.inStates([STATES.ANSWERING, STATES.SHOWING_FEEDBACK])}>
          <PackageAnswers
            answers={answers}
            setDraggedPosition={this.setDraggedPosition}
            disabled={this.inState(STATES.SHOWING_FEEDBACK) || isLimitReached}
          />

          <section className="box-section">
            <Button onClick={isBoxEmpty ? this.skip : this.clear}>
              {isBoxEmpty ? t("common:skip") : t("clear")}
            </Button>

            <Box
              isDragging={dragged.isDragging}
              draggedPosition={dragged.position}
              onHit={this.answerSelected}
            />

            <Button onClick={this.send}>{t("send")}</Button>
          </section>

          <FeedbackCard
            visible={this.inState(STATES.SHOWING_FEEDBACK)}
            content={feedback.message}
            successful={true}
            useDefaultFeedback={false}
            onFinished={this.hideFeedback}
          />

          <AnimationCorrectExplosion
            fixed
            visible={this.inState(STATES.SHOWING_FEEDBACK)}
            position={feedback.position}
          />
        </AnimatedElement>
        <AnimatedElement visible={this.inStates([STATES.ENTERING_EMAIL, STATES.SENDING])}>
          <this.EmailForm />
        </AnimatedElement>
      </>
    )
  }

  EmailForm = () => {
    const { t } = this.props
    const { message, form } = this.state

    return (
      <div className="EmailForm">
        <Card color={Card.COLORS.MAIN}>
          <div className="title">
            <h1 className="description">{t("common:choices.enter_email")}</h1>
            <StampImg className="stamp" />
          </div>
          <div
            className={classNames("message", message.type, {
              visible: message.content,
            })}
          >
            <p>{message.content}</p>
          </div>
          <input name="email" type="email" value={form.email} onChange={this.emailFormChanged} />
          <p>{t("common:choices.email_info")}</p>
        </Card>
        <AnimatedElement
          key="send-button"
          visible={this.inStates([STATES.ENTERING_EMAIL, STATES.SENDING, STATES.SENT])}
        >
          <Button
            className={"send-button"}
            disabled={
              this.state.form.email.length === 0 || this.inStates([STATES.SENDING, STATES.SENT])
            }
            onClick={this.sendAnswers}
          >
            {!this.inStates([STATES.SENDING, STATES.SENT]) && t("send")}
            {this.inStates([STATES.SENDING, STATES.SENT]) && (
              <span>
                <FontAwesomeIcon className="icon" icon={faSpinner} spin />{" "}
                {t("common:choices.sending")}
              </span>
            )}
          </Button>
        </AnimatedElement>
      </div>
    )
  }

  emailFormChanged = (event) => {
    const name = event.target.name
    const value = event.target.value

    this.setState((state) => {
      state.form[name] = value

      return {
        form: state.form,
      }
    })
  }

  sendAnswers = () => {
    const errors = validate(this.state.form, FORM_CONSTRAINTS)

    if (errors) {
      this.setState((state) => {
        let errorString = ""
        for (let errorList of Object.values(errors)) {
          errorString += errorList.join(" ")
        }

        state.message.content = errorString

        return {
          formError: errorString,
        }
      })
    } else {
      this.setCurrentState(STATES.SENDING, () => {
        this.setState(
          {
            message: {
              content: "",
              type: "error",
            },
          },
          this.sendAnswersToServer
        )
      })
    }
  }

  sendAnswersToServer = () => {
    const { t } = this.props

    const chosen = []
    const left = []
    const {
      selectedAnswers,
      question: { current },
      form: { email },
    } = this.state
    const selectedIds = selectedAnswers.map((answer) => answer.id)
    for (const answer of current.answers) {
      if (selectedIds.includes(answer.id)) {
        chosen.push(`<strong>${answer.content}</strong> - ${answer.parameters.feedback.charAt(0).toLowerCase()}${answer.parameters.feedback.slice(1)}`)
      } else {
        left.push(answer.content)
      }
    }

    const success = () => {
      this.setCurrentState(STATES.SENT, () => {
        this.setState(
          {
            message: {
              content: t("common:choices.message_sent"),
              type: "success",
            },
          },
          () => {
            this.delayedSetCurrentState(
              STATES.FINISHING,
              DEFAULT_ANIMATION_SPEED,
              this.onFinish,
              DEFAULT_ANIMATION_SPEED
            )
          }
        )
      })
    }

    axios({
      method: "POST",
      url: SERVER_ADDRESS + (this.type ? `choices/send/${this.type}` : "choices/send"),
      data: {
        email,
        chosen,
        left,
        language: CONFIG.LANGUAGE,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        success()
      })
      .catch(() => {
        this.setCurrentState(STATES.ENTERING_EMAIL, () => {
          this.setState((state) => {
            let errorString = t("common:choices.error")

            state.message.content = errorString

            return {
              formError: errorString,
            }
          })
        })
      })
  }

  onFinish = () => {
    this.finish(false, {
      chosenAnswerIds: this.state.selectedAnswers.map((answer) => answer.id),
    })
  }

  startGame = () => {
    super._questionAppeared(this.state.question)
    this.setCurrentStateSequence(
      [STATES.QUESTION_APPEARING, STATES.ANSWERING],
      TIME_TO_READ_QUESTION_S * 1000
    )
  }

  setDraggedPosition = (position, answer, isDragging) => {
    this.setState({
      dragged: {
        position,
        answer,
        isDragging,
      },
    })
  }

  clear = () => {
    this.setState((state) => ({
      answers: this.prepareAnswers(state.question.current.answers),
      selectedAnswers: [],
    }))
  }

  skip = () => {
    this.setCurrentStateSequence(
      [STATES.QUESTION_FINISHING, STATES.FINISHING],
      DEFAULT_ANIMATION_SPEED
    )
  }

  send = () => {
    this.state.selectedAnswers.forEach((answer) => super._answerChosen(answer))

    this.setCurrentStateSequence(
      [STATES.QUESTION_FINISHING, STATES.ENTERING_EMAIL],
      DEFAULT_ANIMATION_SPEED
    )
  }

  answerSelected = () => {
    this.setState(
      (state) => {
        const answers = [...state.answers]
        const { dragged } = state
        const { position, answer } = dragged

        answers[answer.index].active = false
        Sounds.success.play()

        const feedback = {
          message: answer.feedback,
          position,
        }

        return {
          feedback,
          dragged: {
            answer: undefined,
            position: undefined,
          },

          answers,
          selectedAnswers: [...state.selectedAnswers, answer],
        }
      },
      () => {
        this.setCurrentState(STATES.SHOWING_FEEDBACK)
      }
    )
  }

  hideFeedback = () => {
    this.setCurrentState(STATES.ANSWERING)
  }
}

export default withTranslation(["exercises/package", "common"])(PackageExercise)
