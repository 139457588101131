import React, { useState, useEffect } from "react"
import classNames from "classnames"

import "./ExerciseHints.scss"
import Card from "components/Card/Card"
import Button from "components/Button/Button"
import { useTranslation } from "react-i18next"

let timeout

export default function ExerciseHints({ hints }) {
  const { t } = useTranslation(["common"])
  const [started, setStarted] = useState(false)
  const [current, setCurrent] = useState(0)
  const [hint, setHint] = useState({})
  const [overlayPosition, setOverlayPosition] = useState(undefined)

  useEffect(() => {
    setCurrent(0)

    timeout = setTimeout(() => {
      setStarted(true)
    }, 2000)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  useEffect(() => {
    setHint(hints[current])
  }, [hints, current])

  useEffect(() => {
    const { id, highlightDelayMs } = hint

    if (id !== undefined) {
      setTimeout(() => {
        const element = document.getElementById(id)

        if (element) {
          const boundingRectangle = element.getBoundingClientRect()
          setOverlayPosition({
            x: boundingRectangle.x,
            y: boundingRectangle.y,
            width: boundingRectangle.width,
            height: boundingRectangle.height,
          })
        }
      }, highlightDelayMs)
    }
  }, [hint])

  useEffect(() => {
    if (!started && overlayPosition) {
      setOverlayPosition(undefined)
    }
  }, [overlayPosition, started])

  function close() {
    setStarted(false)
  }

  let overlayStyle
  if (overlayPosition) {
    overlayStyle = {
      left: `${overlayPosition.x - 10}px`,
      top: `${overlayPosition.y - 10}px`,
      width: `${overlayPosition.width + 20}px`,
      height: `${overlayPosition.height + 20}px`,
    }
  }

  return (
    <>
      <Card
        className={classNames("ExerciseHints", { started: started })}
        color={Card.COLORS.BRIGHT}
      >
        {hints[0].content}
        <Button onClick={close}>{t("close")}</Button>
      </Card>
      <div
        className={classNames("ExerciseHints-overlay", { hidden: !overlayPosition })}
        style={overlayStyle}
      />
    </>
  )
}
