import React from "react"
import clsx from "clsx"

import ExerciseComponent from "../../base/ExerciseComponent"
import AnimatedElement from "../../components/AnimatedElement/AnimatedElement"
import InstructionCard from "../../components/InstructionCard"
import Button from "../../components/Button/Button"

import "./VotingExercise.scss"

import { ReactComponent as LoveSmileImg } from "./images/love-smile.svg"
import { ReactComponent as HugeSmileImg } from "./images/huge-smile.svg"
import { ReactComponent as SmileImg } from "./images/smile.svg"
import { ReactComponent as MehImg } from "./images/meh.svg"
import { ReactComponent as FrownImg } from "./images/frown.svg"
import { ReactComponent as HugeFrownImg } from "./images/huge-frown.svg"

import { ReactComponent as Frequency6Img } from "./images/frequency/7.svg"
import { ReactComponent as Frequency5Img } from "./images/frequency/6.svg"
import { ReactComponent as Frequency4Img } from "./images/frequency/5.svg"
import { ReactComponent as Frequency3Img } from "./images/frequency/3.svg"
import { ReactComponent as Frequency2Img } from "./images/frequency/2.svg"
import { ReactComponent as Frequency1Img } from "./images/frequency/1.svg"

import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import { withTranslation } from "react-i18next"

export const FREQUENCY_DESCRIPTION = {
  6: "zawsze",
  5: "bardzo często",
  4: "często",
  3: "czasami",
  2: "rzadko",
  1: "nigdy",
}

export const YES_NO_DESCRIPTION = {
  1: "tak",
  0: "nie",
}

const FREQUENCY_EXTENDED = {
  6: Frequency6Img,
  5: Frequency5Img,
  4: Frequency4Img,
  3: Frequency3Img,
  2: Frequency2Img,
  1: Frequency1Img,
}

const SMILIES_EXTENDED = {
  6: LoveSmileImg,
  5: HugeSmileImg,
  4: SmileImg,
  3: MehImg,
  2: FrownImg,
  1: HugeFrownImg,
}

const FREQUENCY = {
  4: Frequency6Img,
  3: Frequency4Img,
  2: Frequency3Img,
  1: Frequency1Img,
}

const SMILIES = {
  4: HugeSmileImg,
  3: SmileImg,
  2: MehImg,
  1: HugeFrownImg,
}

const STATES = {
  STARTING: 0,
  PLAYING: 1,
  CHANGING_QUESTION: 2,
  FINISHED: -4,
}

class VotingExercise extends ExerciseComponent {
  answersRange = [1, 2, 3, 4]
  images = SMILIES
  showDescriptions = false
  canSkip = false
  yesNo = false
  chosenAnswers = {}
  AnswersComponent = VotingExercise.Answers

  constructor(props) {
    super(props)

    const { parameters, answersComponent } = props
    const { yesNo, extended, frequency, canSkip, showDescriptions } = parameters
    this.canSkip = canSkip ?? this.canSkip
    this.showDescriptions = showDescriptions ?? this.showDescriptions

    this.AnswersComponent = answersComponent ?? VotingExercise.Answers

    if (yesNo) {
      this.yesNo = yesNo
      this.answersRange = [0, 1]
      this.images = {
        1: HugeSmileImg,
        0: HugeFrownImg,
      }
    } else if (extended) {
      this.answersRange = [1, 2, 3, 4, 5, 6]
      if (frequency) {
        this.images = FREQUENCY_EXTENDED
      } else {
        this.images = SMILIES_EXTENDED
      }
    } else if (frequency) {
      this.images = FREQUENCY
    }

    this.state = {
      ...this.state,
      selected: undefined,
      question: props.questions[0],
      answer: props.questions[0].answers[0],
      questionIndex: 0,
      answerIndex: 0,
    }
  }

  usedModules(questions, parameters) {
    return [
      new ChosenAnswerStatsModule(
        {
          resetTimestampInStates: [STATES.PLAYING],
        },
        questions,
        parameters
      ),
    ]
  }

  componentDidMount() {
    this._questionAppeared(this.props.questions[0])
  }

  render() {
    const { t, className } = this.props
    const { answer, selected } = this.state
    const { AnswersComponent } = this

    return (
      <>
        <AnimatedElement
          className={clsx("VotingExercise", className)}
          visible={!this.inStates([STATES.CHANGING_QUESTION, STATES.FINISHED])}
        >
          <InstructionCard
            key="instructions"
            visible={!this.inState(STATES.FINISHED)}
            markdown
            mainText={answer.content}
          />
          <AnswersComponent
            answer={answer}
            answersRange={this.answersRange}
            images={this.images}
            selectedId={selected}
            selectAnswer$={this.selectAnswer$}
            showDescriptions={this.showDescriptions}
            yesNo={this.yesNo}
          />
          {this.canSkip && (
            <Button
              className="skip-button"
              big
              disabled={selected !== undefined}
              onClick={() => {
                this.selectAnswer$(0)()
                this.vote()
              }}
            >
              {t("voting.skip_vote")}
            </Button>
          )}
          <Button big disabled={selected === undefined} onClick={this.vote}>
            {t("continue")}
          </Button>
        </AnimatedElement>
      </>
    )
  }

  static Answers = ({
    answer,
    answersRange,
    images,
    selectedId,
    selectAnswer$,
    showDescriptions,
    yesNo,
  }) => {
    return (
      <div className={`VotingExercise-Answers count-${answersRange.length}`}>
        {answersRange.map((answerId) => {
          const ImageComponent = images[answerId]

          return (
            <div className="answer" key={answerId}>
              <Button
                className={clsx("voting-smiley", `vote-${answerId}-${answersRange.length}`, {
                  selected: selectedId === answerId,
                })}
                onClick={selectAnswer$(answerId)}
              >
                <ImageComponent />
              </Button>
              {!yesNo && showDescriptions && (
                <div className="description" onClick={selectAnswer$(answerId)}>
                  {FREQUENCY_DESCRIPTION[answerId]}
                </div>
              )}
              {yesNo && (
                <div className="description" onClick={selectAnswer$(answerId)}>
                  {YES_NO_DESCRIPTION[answerId]}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  selectAnswer$ = (selected) => () => {
    this.setState({
      selected,
    })
  }

  vote = () => {
    const { selected, questionIndex, answerIndex } = this.state
    const { questions, onQuestionFinished, onExerciseFinished } = this.props
    const question = questions[questionIndex]
    const { answers } = question
    const currentAnswer = answers[answerIndex]

    const processVote =
      this.props.processVote ??
      function (selected, answer) {
        return selected
      }
    const vote = processVote(selected, currentAnswer)
    if (!this.chosenAnswers[questionIndex]) {
      this.chosenAnswers[questionIndex] = []
    }
    this.chosenAnswers[questionIndex].push(vote)

    super._answerChosen(currentAnswer, {
      chosenAnswerOther: {
        vote,
      },
    })

    if (answerIndex < answers.length - 1) {
      this.changeQuestionAnswer(questionIndex, answerIndex + 1)
    } else {
      // Changing question
      if (questionIndex < questions.length - 1) {
        this.changeQuestionAnswer(questionIndex + 1, 0, onQuestionFinished)
      } else {
        this.setCurrentState(
          STATES.FINISHED,
          () => {
            let innerOnQuestionFinished = (resolve) => resolve()
            if (onQuestionFinished) {
              innerOnQuestionFinished = onQuestionFinished
            }
            new Promise((resolve) =>
              innerOnQuestionFinished(resolve, question, this.chosenAnswers[questionIndex], true)
            ).then(() => {
              if (onExerciseFinished) {
                new Promise((resolve) =>
                  onExerciseFinished(resolve, questions, this.chosenAnswers)
                ).then(() => {
                  this.finished()
                })
              } else {
                this.finished()
              }
            })
          },
          1000
        )
      }
    }
  }

  changeQuestionAnswer = (questionIndex, answerIndex, callback) => {
    this.setCurrentState(
      STATES.CHANGING_QUESTION,
      () => {
        const { questionIndex: oldQuestionIndex } = this.state
        const { questions } = this.props
        const oldQuestion = questions[oldQuestionIndex]
        const question = questions[questionIndex]
        const answer = question.answers[answerIndex]

        this.setState({ selected: undefined, questionIndex, answerIndex, question, answer }, () => {
          if (callback) {
            new Promise((resolve) =>
              callback(resolve, oldQuestion, this.chosenAnswers[oldQuestionIndex])
            ).then(() => {
              this.setCurrentState(STATES.PLAYING)
            })
          } else {
            this.setCurrentStateDelayed(STATES.PLAYING, 200)
          }
        })
      },
      500
    )
  }

  finished = () => {
    this.finish(true, {})
  }
}

export default withTranslation(["common"])(VotingExercise)
