import ExercisePointsActions from "./ExercisePointsActions";
import Actions from "../../actions";
import ExerciseActions from "../ExerciseActions";
import getConfig from "../../../@exercises/configs";

const initialState = {
  available: false,
  current: 0,
  max: undefined,
};

const ExercisePointsReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case ExerciseActions.T.LOAD:
      const { data } = payload;
      const config = getConfig(data.slug);

      return {
        ...state,
        available: data.parameters.points.available,
        max: config.maxPoints(data),
      };

    case ExercisePointsActions.T.SET_MAX_POINTS: {
      const { max } = payload;

      return {
        ...state,
        max,
      };
    }

    case ExercisePointsActions.T.CHANGE_POINTS:
      const { change } = payload;

      return {
        ...state,
        current: state.current + change,
      };

    case Actions.T.RESET:
      return { ...initialState };

    default:
      return state;
  }
};

export default ExercisePointsReducer;
