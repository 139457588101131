import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import Sounds from "lib/Sounds"

import "./Button.scss"
import _ from "underscore"

export default class Button extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    throttle: PropTypes.number,

    round: PropTypes.bool,
    big: PropTypes.bool,
  }

  static defaultProps = {
    disabled: false,
    throttle: 1000,

    big: false,
  }

  buttonRef

  constructor(props) {
    super(props)

    this.buttonRef = React.createRef()
  }

  render() {
    return (
      <div
        className={clsx("Button", this.props.className, {
          big: this.props.big,
          disabled: this.props.disabled,
          round: this.props.round,
        })}
        onClick={this.clicked}
        ref={this.buttonRef}
      >
        {this.props.children}
      </div>
    )
  }

  clicked = _.throttle(
    () => {
      if (!this.props.disabled) {
        Sounds.click.play()
        this.props.onClick()
      }
    },
    this.props.throttle,
    { trailing: false }
  )
}

export const AnswerButton = ({ className, children, ...other }) => {
  return (
    <Button className={clsx("SelectableButton AnswerButton", className)} {...other}>
      {children}
    </Button>
  )
}

export const SelectableButton = ({ className, children, selected, ...other }) => {
  return (
    <Button className={clsx("SelectableButton", className, { selected })} throttle={0} {...other}>
      {children}
    </Button>
  )
}
