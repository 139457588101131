const T = {
  RESET: "RESET"
}

export const ActionCreators = {
  noParameters: (type) => () => ({type}),
  singleParameter: (type, key) => (value) => ({
    type,
    payload: {
      [key]: value,
    }
  })
}

const Actions = {
  T,
  reset: ActionCreators.noParameters(T.RESET)
}

export default Actions