import ExerciseActions from "./ExerciseActions"
import {combineReducers} from "redux"
import ExercisePointsReducer from "./points/ExercisePointsReducer"
import Actions from "../actions"
import ExerciseTimeReducer from "./time/ExerciseTimeReducer"

const initialState = null;

export const ExerciseDataReducer = (state = initialState, action) => {
  const {type, payload} = action

  switch (type) {
    case (ExerciseActions.T.LOAD):
      const {data} = payload

      return {...data}

    case (Actions.T.RESET):
      return initialState

    default:
      return state
  }
}

export default combineReducers({
  data: ExerciseDataReducer,
  points: ExercisePointsReducer,
  time: ExerciseTimeReducer,
})