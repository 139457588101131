import React from 'react';
import {defaultTo} from "lodash";
import {Stage, Layer} from "react-konva";

import useGamePosition from "hooks/screen/useGamePosition";
import useGameSize from "hooks/screen/useGameSize";

import ElevatorCard from "./ElevatorCard";
import './ElevatorDoors.scss';


const ElevatorDoors = ({answers, currentFloor, onAnswerChosen}) => {
  const {proportions, width: gameWidth} = useGameSize()
  const {width, height} = useGamePosition({width: [800, 700], height: [1000, 1600]})
  const {x: leftShaft, width: widthShaft, height: heightShaft} =
    useGamePosition({x: [150, 100], width: [500, 500], height: [1000, 1600]})

  const answerElements = answers.map((answer, index) => (
    <ElevatorCard key={answer.id}
      floor={index}
      currentFloor={defaultTo(currentFloor, -1)}
      content={answer.content}
      onAnswerChosen={onAnswerChosen}
    />
  )).reverse();

  return (
    <div className="ElevatorDoors"
      style={{top: 0, left: gameWidth - width, width: width}}
    >
      <Stage
        className="ElevatorStage"

        width={width}
        height={height}
        scale={{x: proportions, y: proportions}}
      >
        <Layer>
          {answerElements}
          {/*<Text text="Try click on rect" />*/}
        </Layer>
      </Stage>
      <div className="shaft"
        style={{
          left: leftShaft,
          width: widthShaft,
          heightShaft: heightShaft,
        }}
      />
    </div>
  )
}

export default ElevatorDoors