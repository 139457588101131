import React from "react"
import _, { defaultTo } from "lodash"

import ExerciseComponent from "base/ExerciseComponent"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import Button from "components/Button/Button"
import InstructionCard from "components/InstructionCard/InstructionCard"
import Sounds from "lib/Sounds"
import FeedbackCard from "components/FeedbackCard"

import ElevatorDoors from "./subcomponents/ElevatorDoors"
import ElevatorButtons from "./subcomponents/ElevatorButtons"
import "./ElevatorExercise.scss"
import PropTypes from "prop-types"
import Card from "components/Card/Card"
import { DEFAULT_STATES } from "base/ExerciseComponent"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import { INSTRUCTION_STEP_TYPES } from "../../base/subcomponents"
import { withTranslation } from "react-i18next"

import exerciseImg from "./img/exercise.jpg"
import exerciseVerticalImg from "./img/exercise-vertical.jpg"

export const DEFAULT_ELEVATOR_CORRECT_POINTS = 2

const STATES = {
  ...DEFAULT_STATES,
  QUESTION_STARTING: 1,
  QUESTION_ANSWERING: 2,
  QUESTION_ANSWERED: 3,
  QUESTION_CHANGING: 4,
  QUESTION_TIME_RAN_OUT: 5,
  QUESTIONS_FINISHED: 6,
  ALL_QUESTIONS_ANSWERED: 7,
}

const DEFAULT_TIME_LIMIT_S = 180

const AUTOMATIC_LAST_ANSWER_TIME_S = 20

class ElevatorExercise extends ExerciseComponent {
  static exerciseClass = "ElevatorExercise"
  chosenAnswerIds = []
  statesAfterFeedback = []

  nextFloorTimeout
  timeForFloorMs

  static propTypes = {
    questions: PropTypes.array,
    parameters: PropTypes.shape({
      timePerQuestionSeconds: PropTypes.number,
      automaticMode: PropTypes.bool, // Do floors change automatically?
    }),

    onFinish: PropTypes.func,
  }

  static maxPoints(questions, parameters) {
    const pointsPerQuestion = defaultTo(
      parameters["pointsPerQuestion"],
      DEFAULT_ELEVATOR_CORRECT_POINTS
    )

    return questions.length * pointsPerQuestion
  }

  constructor(props) {
    super(props)
    const { questions, parameters } = props

    this.state = {
      ...this.state,
      questionIndex: 0,
      points: 0,
      answers: [],
      currentAnswerIndex: undefined,

      timeLimit: props.parameters.timePerQuestionSeconds
        ? parseInt(props.parameters.timePerQuestionSeconds)
        : DEFAULT_TIME_LIMIT_S,
      automaticMode: !!props.parameters.automaticMode,

      automatic: {
        canSkipFloors: true,
        lastChance: false,
      },

      feedback: {
        show: false,
        successful: false,
        content: "",
      },
    }

    this.timePerQuestionSeconds = defaultTo(parameters.timePerQuestionSeconds, DEFAULT_TIME_LIMIT_S)
    this.maxPoints = ElevatorExercise.maxPoints(questions, parameters)
    this.pointsPerQuestion = defaultTo(
      parameters["pointsPerQuestion"],
      DEFAULT_ELEVATOR_CORRECT_POINTS
    )
  }

  usedModules(questions, parameters) {
    return [new ChosenAnswerStatsModule({}, questions, parameters)]
  }

  initInstructions(props) {
    const { t, parameters } = props
    const automaticMode = defaultTo(parameters.automaticMode, false)

    this.instruction = t("game_instruction")
    this.instructions = {
      name: t("name"),
      steps: {
        [INSTRUCTION_STEP_TYPES.TARGET]: t("instruction_target"),
        [INSTRUCTION_STEP_TYPES.EXECUTION]: automaticMode
          ? t("instruction_execution_automatic")
          : t("instruction_execution"),
        [INSTRUCTION_STEP_TYPES.CHOICES]: automaticMode
          ? t("instruction_choices_automatic")
          : t("instruction_choices"),
        [INSTRUCTION_STEP_TYPES.POINTS]: t("instruction_points"),
      },
      imageHorizontal: exerciseImg,
      imageVertical: exerciseVerticalImg,
    }
  }

  renderExercise = (state, props) => {
    const { questions } = props
    const { questionIndex, answers, feedback, currentAnswerIndex, automaticMode, automatic } = state

    return (
      <>
        <InstructionCard
          visible={this.inStates([
            STATES.QUESTION_STARTING,
            STATES.QUESTION_ANSWERING,
            STATES.QUESTION_ANSWERED,
          ])}
          countType="Pytanie"
          countCurrent={questionIndex + 1}
          countMax={questions.length}
          mainText={questions[questionIndex].content}
        />
        <AnimatedElement
          className="answers"
          visible={this.inStates([STATES.QUESTION_ANSWERING, STATES.QUESTION_ANSWERED])}
          zIndex={-2}
        >
          <ElevatorButtons
            currentFloor={currentAnswerIndex}
            floorCount={answers.length}
            changeFloorAction={this.setCurrentAnswerAction}
            goToNextFloor={this.goToNextFloor}
            floorButtonsEnabled={!automaticMode || automatic.lastChance}
            nextButtonVisible={automaticMode}
            nextButtonEnabled={automatic.canSkipFloors}
          />
          <ElevatorDoors
            answers={answers}
            currentFloor={currentAnswerIndex}
            onAnswerChosen={this.answerChosen(currentAnswerIndex)}
          />
        </AnimatedElement>

        <AnimatedElement
          className="fixed"
          visible={this.inState(STATES.QUESTION_STARTING)}
          animation={AnimatedElement.AnimationTypes.popOut}
        >
          <Button big onClick={this.startElevator}>
            Wsiądź do windy
          </Button>
        </AnimatedElement>

        <AnimatedElement
          className="fixed"
          visible={this.inState(STATES.QUESTION_ANSWERING) && automatic.lastChance}
          animation={AnimatedElement.AnimationTypes.popOut}
        >
          <Card className="last-chance" color={Card.COLORS.BRIGHT}>
            To Twoja ostatnia szansa! Wybierz piętro z prawidłową odpowiedzią.
          </Card>
        </AnimatedElement>

        <FeedbackCard
          content={feedback.content}
          visible={this.inState(STATES.QUESTION_ANSWERED) && feedback.show}
          successful={feedback.successful}
          onFinished={this.feedbackFinished}
        />

        <AnimatedElement
          fullSize
          visible={this.inState(STATES.QUESTION_TIME_RAN_OUT)}
          appearDelayMs={500}
        >
          <Card color={Card.COLORS.BRIGHT} className="out-of-time">
            <h1>Koniec czasu!</h1>
          </Card>
          <Button onClick={this.continueGame} big>
            Przejdź dalej
          </Button>
        </AnimatedElement>
      </>
    )
  }

  feedbackFinished = () => {
    this.setCurrentState(STATES.QUESTION_CHANGING, this.continueGame, 1000)
  }

  startGame = () => {
    const { questions } = this.props
    this._questionAppeared(questions[0])
    this.prepareAnswers(() => {
      if (this.state.automaticMode) {
        this.setCurrentState(STATES.QUESTION_STARTING)
      } else {
        this.setCurrentState(STATES.QUESTION_ANSWERING)
      }
    })
  }

  isClockRunning = () => {
    return this.inState(STATES.QUESTION_ANSWERING)
  }

  startElevator = () => {
    this.setCurrentState(STATES.QUESTION_ANSWERING, () => {
      this.goToNextFloor()
    })
  }

  prepareAnswers = (callback = () => {}) => {
    const { questions } = this.props
    const { questionIndex, timeLimit } = this.state

    let answers = _.shuffle(questions[questionIndex].answers).map((answer) => {
      return {
        id: answer["id"],
        content: answer["content"],
        correct: answer["correct"],
        feedback: answer["parameters"]["feedback"],

        open: false,
      }
    })

    this.timeForFloorMs = ((timeLimit - AUTOMATIC_LAST_ANSWER_TIME_S) / answers.length) * 1000

    this.setState(
      {
        answers,
      },
      callback
    )
  }

  goToNextFloor = () => {
    clearTimeout(this.nextFloorTimeout)

    this.setState((state) => {
      let { currentAnswerIndex, automatic } = state
      if (currentAnswerIndex === undefined) {
        currentAnswerIndex = -1
      }

      if (currentAnswerIndex + 1 < state.answers.length) {
        currentAnswerIndex++
        if (state.automaticMode) {
          this.nextFloorTimeout = setTimeout(this.goToNextFloor, this.timeForFloorMs)
        }
      } else {
        currentAnswerIndex = undefined
        automatic = {
          canSkipFloors: false,
          lastChance: true,
        }
      }

      return {
        currentAnswerIndex,
        automatic,
      }
    })
  }

  setCurrentAnswerAction = (currentAnswerIndex) => () => {
    this.setState((state) => {
      let { automatic } = state
      if (state.automaticMode && automatic.lastChance) {
        automatic.lastChance = false
      }

      return {
        currentAnswerIndex,
        automatic,
      }
    })
  }

  answerChosen = (index) => () => {
    if (this.inState(STATES.QUESTION_ANSWERING)) {
      clearTimeout(this.nextFloorTimeout)
      const answer = this.state.answers[index]
      super._answerChosen(answer)

      this.setState(
        (state) => {
          this.chosenAnswerIds.push(answer.id)

          if (answer.correct) {
            Sounds.success.play()
            state.points += this.pointsPerQuestion
            state.feedback.successful = true
          } else {
            Sounds.error.play()
            state.feedback.successful = false
          }

          state.feedback.show = true
          state.feedback.content = answer.feedback

          return {
            points: state.points,
            feedback: state.feedback,
          }
        },
        () => {
          if (this.state.questionIndex + 1 < this.props.questions.length) {
            this.statesAfterFeedback = [STATES.QUESTION_CHANGING]
          } else {
            this.finishData = {
              chosenAnswerIds: this.chosenAnswerIds,
            }
            this.statesAfterFeedback = [STATES.QUESTIONS_FINISHED, STATES.FINISHING]
          }

          this.setCurrentState(STATES.QUESTION_ANSWERED)
        }
      )
    }
  }

  timeRanOut = () => {
    this.setCurrentState(STATES.QUESTION_TIME_RAN_OUT)
  }

  continueGame = () => {
    if (this.state.questionIndex + 1 < this.props.questions.length) {
      this.setState(
        (state) => {
          return {
            currentAnswerIndex: undefined,
            questionIndex: state.questionIndex + 1,
            automatic: {
              canSkipFloors: true,
              lastChance: false,
            },
          }
        },
        () => {
          this._questionAppeared(this.state.questions[this.state.questionIndex])
          this.prepareAnswers(() => {
            if (this.state.automaticMode) {
              this.setCurrentState(STATES.QUESTION_STARTING)
            } else {
              this.setCurrentState(STATES.QUESTION_ANSWERING)
            }
          })
        }
      )
    } else {
      this.setCurrentState(STATES.FINISHING) //, this.finished, DEFAULT_ANIMATION_SPEED)
    }
  }
}

export default withTranslation(["exercises/elevator", "common"])(ElevatorExercise)
