import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'underscore';
import classNames from 'classnames';

import Sounds from 'lib/Sounds';

import PointsBar from "components/PointsBar/PointsBar";
import Animation from "components/Animation";

import Card from "components/Card/Card";
import MemoryCard from "./subcomponents/MemoryCard";
import Button from "components/Button/Button";
import AnimatedElement from "components/AnimatedElement/AnimatedElement";
import importFromDirectory from "lib/ImportFromDirectory";

import './MemoryExercise.scss'

const imageFiles = importFromDirectory(require.context("./img/cards/", false, /\.(jpg)$/));
const IMAGES = {
  1: imageFiles['autumn.jpg'],
  2: imageFiles['forest.jpg'],
  3: imageFiles['iceberg.jpg'],
  4: imageFiles['lake.jpg'],
  5: imageFiles['lighthouse.jpg'],
  6: imageFiles['mountains.jpg'],
  7: imageFiles['reef.jpg'],
  8: imageFiles['waterfall.jpg'],
  9: imageFiles['winter-forest.jpg'],
  10:imageFiles[ 'winter-mountains.jpg'],
};

const ANIMATION_SPEED = 1000;
const FLIP_DELAY = 2000;
const TIME_LIMIT = 120;

class MemoryExercise extends Component {

  static propTypes = {
    goNextAction: PropTypes.func,
  };

  static maxPoints(questions, parameters) {
    if (parameters["onlyImages"]) {
      return 10;
    } else {
      if (questions.length > 0)
        return questions[0].answers.length / 2;
      else
        return 0;
    }
  }

  constructor(props) {
    super(props);

    const {parameters} = props;

    const onlyImages = parameters["onlyImages"];
    const timeLimit = parameters["timePerQuestionSeconds"] ? parameters["timePerQuestionSeconds"] : TIME_LIMIT;
    const showSummary = parameters["showSummary"];

    this.state = {
      visible: true,

      answers: [],
      lastAnswerIndex: undefined,

      clockRunning: false,
      finished: false,
      timeRanOut: false,
      points: 0,
      maxPoints: MemoryExercise.maxPoints(props.questions, props.parameters),
      playing: true,

      timeLimit,
      timeLeft: timeLimit,
      showSummary,

      onlyImages: onlyImages,

      changed: 0,
    };

    if (onlyImages) {
      for (let i = 1; i <= 10; i++) {
        for (let j = 1; j <= 2; j++) {
          this.state.answers.push({
            id: i,
            image: IMAGES[i],
            revealed: false,
            answered: false,
            text: false,
          });
        }
      }
    } else {
      for (let answer of props.questions[0].answers) {
        this.state.answers.push({
          id: answer.parameters.id,
          image: `images/medium/3x2/${answer.parameters.image}.jpg`,
          revealed: false,
          answered: false,
          text: answer.content,
        });
      }
    }

    this.state.answers = _.shuffle(this.state.answers);
  }

  answerChosen = (answerIndex) => {
    if (!this.state.playing || this.state.answers[answerIndex].revealed) {
      return;
    }

    this.setState((state) => {
      let {lastAnswerIndex, points, finished, answers, onlyImages} = state;
      let answer = answers[answerIndex];
      answer.revealed = true;
      if (onlyImages) {
        answer.answered = true;
      }

      if (lastAnswerIndex !== undefined) {
        let lastAnswer = answers[lastAnswerIndex];
        if (answer.id === lastAnswer.id) {
          Sounds.success.play();
          points++;
          answer.answered = true;
          lastAnswer.answered = true;

          if (points === this.state.answers.length / 2) {
            finished = true;
          }
        } else {
          Sounds.error.play();
          setTimeout(this.hideAnswers.bind(this, [answerIndex, lastAnswerIndex]), FLIP_DELAY)
        }

        lastAnswer = undefined;
        lastAnswerIndex = undefined;
      } else {
        Sounds.click.play();
        lastAnswerIndex = answerIndex;
      }

      return {
        answers,
        finished,
        points,
        lastAnswerIndex,

        clockRunning: true,
      }
    });
  };

  hideAnswers = (answerIndexList) => {
    this.setState((state) => {
      let {answers} = state;
      for (let answerIndex of answerIndexList) {
        answers[answerIndex].revealed = false;
      }

      return {
        answers,
      }
    })
  };

  timeChanged = timeLeft => {
    this.setState({
      timeLeft,
    })
  };


  timeRanOut = () => {
    this.setState({
      playing: false,
      finished: true,
      timeRanOut: true,
      timeLeft: 0,
    });
  };

  goNext = () => {
    this.setState({
      visible: false,
    });
    setTimeout(this._goNext, ANIMATION_SPEED);
  };

  _goNext = () => {
    this.props.goNextAction(this.state.points);
  };

  render() {
    const {onlyImages, timeLimit, points, maxPoints, showSummary, timeLeft, finished, timeRanOut} = this.state;
    const {questions} = this.props;

    const timePassed = timeLimit - timeLeft;

    let answerElements = this.state.answers.map((answer, index) => {
      return <MemoryCard key={index} id={index}
        content={answer.text} image={answer.image}
        revealed={answer.revealed} answered={answer.answered}
        onClick={this.answerChosen} />
    });
    

    return (
      <Animation type="fade" active={this.state.visible}>
        <div className={classNames("MemoryExercise", {
          "only-images": onlyImages,
        })}>
          <PointsBar
            points={points} maxPoints={maxPoints}
            timeLimit={timeLimit} clockRunning={this.state.clockRunning && !this.state.finished}
            onTimeRanOut={this.timeRanOut}
            onSecondPassed={this.timeChanged}
            instruction={onlyImages ? "Odkryj pary identycznych zdjęć" : questions[0].content}
          />
          <div className={classNames('answers-container',
            {
              'game-finished': this.state.timeRanOut || this.state.finished,
              'large': this.state.answers.length <= 12,
            })}
          >
            { answerElements }
          </div>
          <AnimatedElement className="time-finished-container" visible={timeRanOut}>
            <Card>
              <h1>Koniec czasu!</h1>
              {showSummary &&
                <h2>Znalezione pary: {points}</h2>
              }
            </Card>
          </AnimatedElement>
          <AnimatedElement className="time-finished-container" visible={!timeRanOut && finished && showSummary}>
            <Card>
              <h1>Brawo!</h1>
              <h2>Odkrycie wszystkich elementów zajęło Ci {timePassed} sekund</h2>
            </Card>
          </AnimatedElement>
          <AnimatedElement className="next-button-container" visible={this.state.finished} animation={AnimatedElement.AnimationTypes.popOut} appearDelayMs={FLIP_DELAY}>
              <Button big onClick={this.goNext}>
                Przejdź dalej
              </Button>
          </AnimatedElement>
        </div>
      </Animation>
    );
  }
}

export default MemoryExercise;