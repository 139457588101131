export default class Module {
  hasEvents = false;

  questions;
  parameters;
  exerciseParameters;

  constructor(parameters, questions, exerciseParameters) {
    this.parameters = parameters;
    this.questions = questions;
    this.exerciseParameters = exerciseParameters;
  }

  stateActions() {
    return [];
  }

  isActive() {
    return true;
  }

  // To be overwritten in modules
  questionAppeared(question) {}
  answerChosen(answer) {}

  shouldShowSummary() {
    return false;
  }

  renderSummary() {
    return "";
  }
}