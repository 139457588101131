import React, { useEffect, useRef, useState } from "react"
import SzymonVotingExercise from "./SzymonVotingExercise"

import AnimatedElement from "../../../components/AnimatedElement/AnimatedElement"
import Card from "../../../components/Card/Card"
import Button from "../../../components/Button"

import "./CourageVotingExercise.scss"
import clsx from "clsx"
import Markdown from "../../../components/Markdown"

const VOTE_MAP_CORRECT = {
  6: 4,
  5: 2,
  4: 1,
  3: -1,
  2: -2,
  1: -4,
}

const VOTE_MAP_INCORRECT = {
  6: -4,
  5: -2,
  4: -1,
  3: 1,
  2: 2,
  1: 4,
}

const CourageVotingExercise = (props) => {
  return (
    <SzymonVotingExercise
      className="CourageVotingExercise"
      voteMapCorrect={VOTE_MAP_CORRECT}
      voteMapIncorrect={VOTE_MAP_INCORRECT}
      ScaleSummaryComponent={CourageVotingScaleSummary}
      QuestionSummaryComponent={CourageVotingQuestionSummary}
      ExerciseSummaryComponent={CourageVotingExerciseSummary}
      {...props}
    />
  )
}

const CourageVotingScaleSummary = ({ points, pointsScale, pointsLabels }) => {
  const [position, setPosition] = useState(50)
  const timeoutRef = useRef(null)

  useEffect(() => {
    timeoutRef.current = setTimeout(
      () => setPosition(((points + pointsScale) / (2 * pointsScale)) * 100),
      250
    )

    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [points, pointsScale])

  return (
    <div className="CourageVotingScaleSummary ScaleSummary">
      <div className={clsx("left-label", { active: position < 50 })}>{pointsLabels[0]}</div>
      <div className={clsx("right-label", { active: position > 50 })}>{pointsLabels[1]}</div>
      {/*<i className={clsx("fas fa-angle-double-left", { active: position < 50 })} />*/}
      {/*<i className={clsx("fas fa-angle-double-right", { active: position > 50 })} />*/}
      <div className="slider" style={{ left: `${position}%` }} />
    </div>
  )
}

const CourageVotingQuestionSummary = ({
  visible,
  title,
  description,
  points,
  pointsScale,
  pointsLabels,
  resultName,
  comment,
  isLastQuestion,
  onNext,
}) => {
  return (
    <AnimatedElement visible={visible} className="CourageVotingQuestionSummary QuestionSummary">
      <Card>
        <h1 className="title">{title}</h1>
        <CourageVotingScaleSummary
          points={points}
          pointsScale={pointsScale}
          pointsLabels={pointsLabels}
        />
      </Card>
      <Card>
        <Markdown className="result-comment">{comment}</Markdown>
      </Card>
      <Button big onClick={onNext}>
        {isLastQuestion ? "Przejdź do podsumowania testu" : "Przejdź do dalszej części testu"}
      </Button>
    </AnimatedElement>
  )
}

const CourageVotingExerciseSummary = ({
  exerciseId,
  visible,
  onNext,
  summary,
  ScaleSummaryComponent,
}) => {
  return (
    <AnimatedElement visible={visible} className="ExerciseSummary">
      <Card>
        <h1 className="title">Twój wynik</h1>
        {summary.map((questionInfo, index) => (
          <ScaleSummaryComponent key={index} {...questionInfo} />
        ))}
      </Card>
      <Card>
        <p style={{ marginBottom: "1em" }}>
          Ten test został opracowany na podstawie teorii Poliwagalnej i wyników badań prof. Carol S.
          Dweck z Uniwersytetu Stanford. Najważniejszym wnioskiem wynikającym z obu badań jest to,
          że możesz wzmacniać w sobie nastawienie na rozwój i budować nowe wzorce autonomiczne w
          reakcji na trudne sytuacje.
        </p>
        <p>Zobacz co robić, aby wzmacniać w sobie i innych odwagę w działaniu.</p>
      </Card>
      <Button big onClick={onNext}>
        Przejdź dalej
      </Button>
    </AnimatedElement>
  )
}

export default CourageVotingExercise
