import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import EXERCISE_COMPONENTS from "../../exercises/enums/exerciseComponents"
import create from "zustand"
import default_config from "../../@exercises/configs/default_config"

const DEFAULT_STATE = { config: default_config }

export const useExerciseState = create((set) => ({
  ...DEFAULT_STATE,
  resetState: (overrides = {}) => set({ ...DEFAULT_STATE, ...overrides }),
}))

export const getExerciseComponent = ({ slug }) => (slug ? EXERCISE_COMPONENTS[slug] : null)

export const useExerciseTranslation = (config) => {
  const { slug } = config

  const { i18n, ready } = useTranslation([slug, "common"])
  const [translations, setTranslations] = useState({
    initialised: false,
    instructions: {},
    hints: [],
  })

  const t = i18n.t.bind(i18n)

  useEffect(() => {
    if (ready) {
      const newNamespace = `exercises/${slug}`
      i18n.loadNamespaces(newNamespace).then(() => {
        i18n.setDefaultNamespace(newNamespace)

        setTranslations({
          initialised: true,
          instructions: config.instructions(t),
          hints: config.hints(t),
        })
      })
    }
    // eslint-disable-next-line
  }, [config, ready])

  return {
    t,
    initialised: translations.initialised,
    instructions: translations.instructions,
    hints: translations.hints,
  }
}
