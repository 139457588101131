import { defaultsDeep } from "lodash"
import default_config from "./default_config"

export function getBaseConfig(slug) {
  return require(`exercises/${slug}/${slug}_config`).default
}

export default function getConfig(exercise) {
  const { slug } = exercise

  const loaded_config = { ...exercise }
  defaultsDeep(loaded_config, getBaseConfig(slug), default_config)

  return loaded_config
}
