import React, { useCallback, useState } from "react"
import { Trans } from "react-i18next"
import { ANSWERS_STATES as AS } from "../../@exercises/Exercise"
import Element from "../../components/Element"

import Button, { SelectableButton } from "../../components/Button/Button"
import { useList, useSet } from "react-use"
import Scrollable from "../../components/Scrollable"

import "./ValuesExercise.scss"
import Card from "../../components/Card/Card"
import { DEFAULT_ANIMATION_SPEED } from "../../app/Sprint"

const ValuesExercise = ({
  t,
  answers,
  question,
  parameters,
  state,
  inAnswersState,
  answerChosen,
  nextQuestion,
  isLastQuestion,
  finish,
}) => {
  const [currentlySelected, { toggle: toggleCurrent, has: hasCurrent, reset: resetCurrent }] =
    useSet(new Set([]))
  const [importantSelected, { toggle: toggleImportant, has: hasImportant }] = useSet(new Set([]))
  const [allSelected, { push }] = useList([])
  const {
    parameters: { summary, limit },
  } = question
  let { summaryLimit } = parameters
  summaryLimit = Math.min(summaryLimit ? summaryLimit : 3, allSelected.length)

  const chooseAnswers = useCallback(
    (useCurrent = false) => {
      let answers = [...allSelected]
      if (useCurrent) answers = [...answers, ...currentlySelected]

      answers.forEach((answer) => {
        const important = hasImportant(answer)
        answerChosen(answer, { other: { important } })
      })
    },
    [answerChosen, hasImportant, allSelected, currentlySelected]
  )

  const next = useCallback(() => {
    if (summary) {
      chooseAnswers()
      finish()
    } else {
      if (isLastQuestion) {
        chooseAnswers(true)
        finish()
      } else {
        push(...Array.from(currentlySelected))
        setTimeout(() => resetCurrent(), DEFAULT_ANIMATION_SPEED)
        nextQuestion()
      }
    }
  }, [
    nextQuestion,
    resetCurrent,
    push,
    currentlySelected,
    summary,
    chooseAnswers,
    isLastQuestion,
    finish,
  ])

  console.log(answers, currentlySelected)

  return (
    <div className="ValuesExercise">
      <Element active={inAnswersState([AS.APPEARING, AS.ACTIVE])}>
        {summary ? (
          <ValuesExerciseAnswers
            t={t}
            summary={true}
            answers={allSelected}
            toggle={toggleImportant}
            has={hasImportant}
            size={importantSelected.size}
            limit={summaryLimit}
          />
        ) : (
          <ValuesExerciseAnswers
            t={t}
            answers={answers}
            toggle={toggleCurrent}
            has={hasCurrent}
            size={currentlySelected.size}
            limit={limit}
          />
        )}

        <Button big={true} onClick={next} disabled={summary && importantSelected.size === 0}>
          {t("common:continue")}
        </Button>
      </Element>
    </div>
  )
}

const ValuesExerciseAnswers = ({ t, answers, toggle, has, size, limit, summary = false }) => {
  const [isWarningShown, setIsWarningShown] = useState(summary)

  return (
    <div className="ValuesExerciseAnswers">
      <Element className="answers-warning" active={isWarningShown}>
        <Card>
          <div className="icon">
            <i className="fas fa-exclamation" />
          </div>
          <div className="content">
            <p>
              <Trans i18nKey="summary_warning" t={t}>
                Teraz wybierz maksymalnie <strong>3</strong> wartości, które uważasz za
                najważniejsze wśród tych, które zaznaczyłaś/eś na poprzednich ekranach.
              </Trans>
            </p>
            <Button onClick={() => setIsWarningShown(false)}>{t("common:begin")}</Button>
          </div>
        </Card>
      </Element>
      <Scrollable>
        {answers.map((answer) => (
          <SelectableButton
            className="answer-button"
            key={answer.id}
            selected={has(answer)}
            disabled={isWarningShown || (!has(answer) && size >= limit)}
            onClick={() => toggle(answer)}
            big={true}
          >
            {answer.content}
          </SelectableButton>
        ))}
      </Scrollable>
    </div>
  )
}

export default ValuesExercise
