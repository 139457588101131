import React, { useEffect, useMemo, useRef } from "react"
import Konva from "konva"
import { Group, Image, Rect, Text } from "react-konva"
import COLORS from "../../../enums/colors"
import usePositions from "hooks/screen/usePositions"
import { useTextSize } from "../../../enums/text_size"
import { CARD_MARGIN, CARD_SIZE } from "."
import useImage from "use-image"
import { useBoolean } from "react-use"
import sprintLogo from "images/logo-white.svg"
import { CARD_SUITS } from "../ConversationsExercise"
import { random } from "lodash"

export const CONVERSATION_STARTER_ID = "ConversationStarterRect"

function calcClipFunc(ctx, x, y, width, height, radius) {
  ctx.beginPath()
  ctx.moveTo(x + radius, y)
  ctx.lineTo(x + width - radius, y)
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius)
  ctx.lineTo(x + width, y + height - radius)
  ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height)
  ctx.lineTo(x + radius, y + height)
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius)
  ctx.lineTo(x, y + radius)
  ctx.quadraticCurveTo(x, y, x + radius, y)
  ctx.closePath()
}

const ConversationStarter = ({ content, image }) => {
  const enterAnimationR = useRef()
  const timeoutR = useRef()
  const nodeR = useRef()
  const suitIdR = useRef(random(0, 3))

  const fontSize = useTextSize("sm")
  const [showFront, toggleShowFront] = useBoolean(false)
  const [{ x, y }, { x: width, y: height }, { x: margin }] = usePositions([
    {
      x: [(1 - CARD_SIZE.x[0]) / 2, (1 - CARD_SIZE.x[1]) / 2],
      y: [0.16, 0.12],
    },

    CARD_SIZE,
    CARD_MARGIN,
  ])
  const [logo] = useImage(sprintLogo)
  const logoRatio = useMemo(() => (logo ? logo.height / logo.width : 1), [logo])
  const logoWidth = useMemo(() => (5 * width) / 12, [width])
  const logoHeight = useMemo(() => logoWidth * logoRatio, [logoRatio, logoWidth])

  const [imageK] = useImage(image)

  const [cardSuitImg] = useImage(CARD_SUITS[suitIdR.current])
  const suitRatio = useMemo(() => (cardSuitImg ? cardSuitImg.height / cardSuitImg.width : 1), [
    cardSuitImg,
  ])
  const suitWidth = useMemo(() => (5 * width) / 12, [width])
  const suitHeight = useMemo(() => suitWidth * suitRatio, [suitRatio, suitWidth])

  useEffect(() => {
    const duration = 0.2

    if (enterAnimationR.current) {
      enterAnimationR.current.reset()
    }

    timeoutR.current = setTimeout(() => {
      enterAnimationR.current = new Konva.Tween({
        node: nodeR.current,
        duration,

        scaleX: 1.1,
        scaleY: 0,
        rotation: 0,

        easing: Konva.Easings.EaseIn,

        onFinish: () => {
          toggleShowFront()
          enterAnimationR.current = new Konva.Tween({
            node: nodeR.current,
            duration,

            scaleX: 1,
            scaleY: 1,
            rotation: 0,

            easing: Konva.Easings.EaseOut,
          })
          enterAnimationR.current.play()
        },
      })

      enterAnimationR.current.play()
    }, 1500)

    return () => {
      clearTimeout(timeoutR.current)
    }
    // eslint-disable-next-line
  }, [x, y, height, width, content])

  return (
    <Group
      x={x + width / 2}
      y={y + height / 2}
      offsetX={width / 2}
      offsetY={height / 2}
      clipFunc={(ctx) => calcClipFunc(ctx, 0, 0, width, height, 0.1 * height)}
      id="ConversationStarter"
      ref={(n) => (nodeR.current = n)}
    >
      <Rect
        id={CONVERSATION_STARTER_ID}
        width={width}
        height={height}
        fill={COLORS.MAIN}
        strokeWidth={0.06 * height}
        stroke={imageK && showFront ? null : COLORS.WHITE}
        cornerRadius={0.1 * height}
      />
      {showFront && (
        <Group>
          {imageK && (
            <Group clipFunc={(ctx) => calcClipFunc(ctx, 0, 0, width, height, 0.1 * height)}>
              <Image image={imageK} width={width} height={height} />
              <Rect width={width} height={height} fill={COLORS.BLACK} opacity={0.7} />
            </Group>
          )}
          <Image
            image={cardSuitImg}
            x={width / 2}
            y={height / 2}
            offsetX={suitWidth / 2}
            offsetY={suitHeight / 2}
            width={suitWidth}
            height={suitHeight}
            rotation={90}
            opacity={0.2}
          />
          <Text
            x={margin}
            width={width - 2 * margin}
            y={margin}
            height={height - 2 * margin}
            align="center"
            verticalAlign="middle"
            fill={COLORS.WHITE}
            fontFamily={COLORS.FONT}
            text={content}
            fontSize={fontSize}
            lineHeight={1.25}
          />
        </Group>
      )}
      {!showFront && (
        <Image
          image={logo}
          x={(width - logoWidth) / 2}
          y={(height - logoHeight) / 2}
          width={logoWidth}
          height={logoHeight}
          opacity={0.5}
        />
      )}
    </Group>
  )
}

export default ConversationStarter
