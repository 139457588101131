import Konva from "konva"
import COLORS from "../enums/colors"

export const onClickableHover = (ref, tween, { changeFill = true, changeStroke = true }) => () => {
  if (tween) tween.pause()
  const config = {
    node: ref,
    duration: 0.25,
    easing: Konva.Easings.EaseInOut,
  }
  if (changeFill) config.fill = COLORS.CLICKABLE_HOVER
  if (changeStroke) config.stroke = COLORS.CLICKABLE_HOVER
  tween = new Konva.Tween(config)
  ref.getStage().container().style.cursor = "pointer"
  tween.play()
}

export const onClickableHoverLeave = (
  ref,
  tween,
  { changeFill = true, changeStroke = true }
) => () => {
  if (tween) tween.pause()
  const config = {
    node: ref,
    duration: 0.25,
    easing: Konva.Easings.EaseInOut,
  }
  if (changeFill) config.fill = COLORS.CLICKABLE
  if (changeStroke) config.stroke = COLORS.CLICKABLE
  tween = new Konva.Tween(config)
  ref.getStage().container().style.cursor = "default"
  tween.play()
}
