import imgHorizontal from "images/exercises/conversations/exercise-horizontal.jpg"
import imgVertical from "images/exercises/conversations/exercise-vertical.jpg"
import {
  NEXT_QUESTION_TYPE,
  POINT_TYPES,
  QUESTION_FINISH_TYPES,
} from "../../@exercises/configs/default_config"
import { SIDES } from "../../state/$sprint"

const conversations_config = {
  images: {
    horizontal: imgHorizontal,
    vertical: imgVertical,
  },
  parameters: {
    sides: {
      [SIDES.LEFT]: true,
      [SIDES.RIGHT]: true,
      [SIDES.TOP]: true,
      [SIDES.BOTTOM]: true,
    },
    questions: {
      show: false,
      next: NEXT_QUESTION_TYPE.IN_ANSWER,
    },
    answers: {
      sounds: true,
      finish_type: QUESTION_FINISH_TYPES.FIRST,
    },
    time: {
      enabled: false,
    },
    points: {
      per_correct_answer: POINT_TYPES.PER_ANSWER,
      max: 0,
    },
  },
  maxPoints: function () {
    return this.parameters.points.max
  },
}

export default conversations_config
