import React, { useCallback, useRef, useState } from "react"

import "./Scrollable.scss"
import clsx from "clsx"
import { useMap, useMount } from "react-use"
import Button from "./Button"
import Element from "./Element"

const Scrollable = ({ children, className }) => {
  const ref = useRef(null)
  const [canScroll, setCanScroll] = useState(false)

  const scrolled = useCallback(({ target }) => {
    if (target.scrollTop === 0 && target.clientHeight !== target.scrollHeight) {
      setCanScroll(true)
    } else {
      setCanScroll(false)
    }
  }, [])

  useMount(() => {
    setTimeout(() => scrolled({ target: ref.current }))
  })

  return (
    <>
      <div className="ScrollableComponent">
        <div className={clsx(className, "scrollable")} ref={ref} onScroll={scrolled}>
          {children}
          <Element className="scroll-indicator" active={canScroll} durationMs={250}>
            <i className="fas fa-angle-double-down" />
          </Element>
        </div>
      </div>
    </>
  )
}

export const ScrollableWithButtons = ({ children, className }) => {
  const ref = useRef(null)
  const [canScroll, { setAll }] = useMap({
    top: false,
    bottom: false,
  })

  const scrolled = useCallback(
    ({ target }) => {
      const newScroll = { top: false, bottom: false }

      if (target.scrollTop !== 0) {
        newScroll.top = true
      }

      if (target.scrollTop + target.clientHeight < target.scrollHeight) {
        newScroll.bottom = true
      }

      setAll(newScroll)
    },
    [setAll]
  )

  const scroll = useCallback((up = false) => {
    const target = ref.current

    target.scroll({
      top: target.scrollTop + (up ? -target.clientHeight * 0.8 : target.clientHeight * 0.8),
      left: 0,
      behavior: "smooth",
    })
  }, [])

  useMount(() => {
    setTimeout(() => scrolled({ target: ref.current }))
  })

  return (
    <>
      <div className="ScrollableComponent">
        <div className={clsx(className, "scrollable")} ref={ref} onScroll={scrolled}>
          {children}
          <Element className="scroll-button scroll-up" active={canScroll.top} durationMs={250}>
            <Button onClick={() => scroll(true)} throttle={0}>
              <i className="fas fa-angle-double-up" />
            </Button>
          </Element>
          <Element className="scroll-button scroll-down" active={canScroll.bottom} durationMs={250}>
            <Button onClick={() => scroll(false)} throttle={0}>
              <i className="fas fa-angle-double-down" />
            </Button>
          </Element>
        </div>
      </div>
    </>
  )
}

export default Scrollable
