import {ActionCreators} from "../../actions"

const T = {
  CHANGE_POINTS: "EXERCISE__CHANGE_POINTS",
  SET_MAX_POINTS: "EXERCISE__SET_MAX_POINTS",
}
const root = (state) => state.exercise.points

const ExercisePointsActions = {
  T,
  S: {
    AVAILABLE: state => root(state).available,
    CURRENT_POINTS: state => root(state).current,
    MAX_POINTS: state => root(state).max,
  },

  changePoints: ActionCreators.singleParameter(T.CHANGE_POINTS, "change"),
  setMaxPoints: ActionCreators.singleParameter(T.SET_MAX_POINTS, "max"),
}

export default ExercisePointsActions