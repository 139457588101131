import React, { Component } from "react"
import PropTypes from "prop-types"
import clsx from "clsx"

import "./AnswerCard.scss"
import Sounds from "../../lib/Sounds"
import { Translation } from "react-i18next"

export default class AnswerCard extends Component {
  static propTypes = {
    showFeedback: PropTypes.bool,
    disabled: PropTypes.bool,

    isCorrect: PropTypes.bool,
    isBlurred: PropTypes.bool,
    small: PropTypes.bool,
    answer: PropTypes.any,

    onClick: PropTypes.func,
    onBlurredClick: PropTypes.func,
  }

  static defaultProps = {
    showFeedback: false,
    disabled: false,
    isCorrect: false,
    small: false,

    onClick: () => {},
  }

  constructor(props) {
    super(props)

    this.state = {
      showFeedback: false,
    }
  }

  render() {
    let { answer, disabled, isCorrect, isBlurred, small } = this.props
    let { showFeedback } = this.state

    const { neutralFeedback } = answer

    return (
      <div
        onClick={this.clicked}
        className={clsx("AnswerCard", {
          small,
          "show-feedback": showFeedback,
          correct: isCorrect && !neutralFeedback,
          blurred: isBlurred,
          disabled: disabled && !showFeedback,
        })}
      >
        <div className="disabled-overlay" />
        <div className="content">{this.props.children}</div>
        <div
          className={clsx("feedback", {
            correct: showFeedback && (neutralFeedback || isCorrect),
          })}
        >
          {neutralFeedback && [
            <i className="fas fa-check" key="icon" />,
            <div className="feedback-description" key="description">
              <Translation ns={"common"}>
                {(t) => (
                  <>
                    <h2>{t("thanks")}! :)</h2>
                  </>
                )}
              </Translation>
            </div>,
          ]}
          {!neutralFeedback &&
            isCorrect && [
              <i className="fas fa-check" key="icon" />,
              <div className="feedback-description" key="description">
                <Translation ns={"common"}>
                  {(t) => (
                    <>
                      <h2>{t("great")}!</h2>
                    </>
                  )}
                </Translation>
              </div>,
            ]}
          {!neutralFeedback &&
            !isCorrect && [
              <i className="fas fa-times" key="icon" />,
              <div className="feedback-description" key="description">
                <Translation ns={"common"}>
                  {(t) => (
                    <>
                      <h2>{t("oops")}...</h2>
                      <p>{t("wrongAnswer")}!</p>
                    </>
                  )}
                </Translation>
              </div>,
            ]}
        </div>
      </div>
    )
  }

  clicked = (event) => {
    let { onClick, onBlurredClick, disabled, answer, isCorrect, isBlurred } = this.props
    let { showFeedback } = this.state
    const { neutralFeedback } = answer
    event.persist()

    if (!disabled && !showFeedback) {
      if (isBlurred) {
        onBlurredClick(answer, event)
      } else {
        this.setState(
          {
            showFeedback: true,
          },
          () => {
            if (neutralFeedback || isCorrect) {
              Sounds.success.play()
            } else {
              Sounds.error.play()
            }

            onClick(answer, event)
          }
        )
      }
    }
  }
}
