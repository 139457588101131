import React from "react"
import propTypes from "prop-types"
import ReactMarkdown from "react-markdown"
import classNames from "classnames"
import validate from "validate.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSpinner } from "@fortawesome/free-solid-svg-icons"

import _ from "lodash"

import ExerciseComponent, { DEFAULT_STATES } from "base/ExerciseComponent"
import "./ChoicesExercise.scss"
import AnimatedElement from "components/AnimatedElement/AnimatedElement"
import InstructionCard from "components/InstructionCard"
import Card from "components/Card/Card"
import Button, { SelectableButton } from "components/Button/Button"
import ChosenAnswerStatsModule from "../../@exercises/modules/stats/ChosenAnswerStatsModule"
import axios from "axios"
import { SERVER_ADDRESS } from "../../app/Sprint"
import Scrollable from "../../components/Scrollable"
import { withTranslation } from "react-i18next"
import CONFIG from "../../config"

const DEFAULT_ANSWERS_LIMIT = 3
const DEFAULT_SHOULD_SEND_EMAIL_WITH_COMMENTS = false
const ANIMATION_DURATION = 0

const MAX_FAILURES = 3

const STATES = {
  CHOOSING_ANSWERS: 0,
  HIDING_ANSWERS: 1,
  ENTERING_EMAIL: 2,
  SENDING: 3,
  SENT: 4,
  FINISHING: DEFAULT_STATES.FINISHING,
  FINISHED: DEFAULT_STATES.FINISHED,
}

class ChoicesExercise extends ExerciseComponent {
  shouldSendEmailWithComments = false
  failCount = 0
  canSkip = false
  type = null

  static usedModules = [ChosenAnswerStatsModule]

  static propTypes = {
    questions: propTypes.arrayOf(
      propTypes.shape({
        content: propTypes.string,
        answers: propTypes.arrayOf(
          propTypes.shape({
            content: propTypes.string,
          })
        ),
      })
    ),
    parameters: propTypes.shape({
      answersLimit: propTypes.number,
      shouldSendEmailWithComments: propTypes.bool,
      canSkip: propTypes.bool,
    }),

    onFinish: propTypes.func,
  }

  static fieldConstraints = {
    email: {
      email: {
        message: "^Podaj prawidłowy adres e-mail.",
      },
    },
    comment: {},
  }

  constructor(props) {
    super(props)

    const { t } = props
    ChoicesExercise.fieldConstraints.email.email.message = t("choices.no_mail")

    this.shouldSendEmailWithComments = _.defaultTo(
      this.props.shouldSendEmailWithComments,
      DEFAULT_SHOULD_SEND_EMAIL_WITH_COMMENTS
    )

    this.canSkip = props.parameters.canSkip ?? false
    if (props.parameters.type) {
      this.type = props.parameters.type
    }

    this.state = {
      ...this.state,
      current: STATES.CHOOSING_ANSWERS,
      selectedIds: [],

      form: {
        email: "",
        comment: "",
      },

      message: {
        content: "",
        type: "error",
      },

      questionIndex: 0,
    }
  }

  componentDidMount() {
    super._questionAppeared(this.props.questions[0])
  }

  usedModules(questions, parameters) {
    return [new ChosenAnswerStatsModule({}, questions, parameters)]
  }

  render() {
    return (
      <AnimatedElement
        visible={!this.inStates([STATES.FINISHING, STATES.FINISHED])}
        className="ChoicesExercise"
      >
        <AnimatedElement visible={this.inState(STATES.CHOOSING_ANSWERS)}>
          {this.renderChoosingAnswers()}
        </AnimatedElement>
        <AnimatedElement
          visible={this.inStates([STATES.ENTERING_EMAIL, STATES.SENDING, STATES.SENT])}
        >
          {this.renderEnteringEmail()}
        </AnimatedElement>
      </AnimatedElement>
    )
  }

  renderChoosingAnswers = () => {
    const { t } = this.props
    const question = this.getCurrentQuestion()
    const { selectedIds } = this.state

    let answers = question.answers.map((answer, index) => {
      const selected = selectedIds.includes(answer.id)
      return (
        <SelectableButton
          key={index}
          className={classNames({
            selected: selected,
            disabled: !this.canChooseAnswer() && !selected,
          })}
          color={selected ? Card.COLORS.SUCCESS : Card.COLORS.FAILURE}
          onClick={this.answerSelected(answer)}
        >
          <ReactMarkdown source={answer.content} />
        </SelectableButton>
      )
    })

    return [
      <InstructionCard
        key="instructions"
        visible={this.inState(STATES.CHOOSING_ANSWERS)}
        mainText={question.content}
      />,
      <Scrollable key="answers" className="answers">
        {answers}
      </Scrollable>,
      <AnimatedElement
        key="next-button"
        className="buttons"
        visible={this.inState(STATES.CHOOSING_ANSWERS)}
      >
        <Button
          onClick={() => this.answersChosen()}
          disabled={selectedIds.length === 0 && !this.canSkip}
          big
        >
          {t("continue")}
        </Button>
        {this.shouldSendEmailWithComments && (
          <Button onClick={() => this.answersChosen(true)} disabled={selectedIds.length === 0} big>
            {t("choices.send_to_self")}
          </Button>
        )}
      </AnimatedElement>,
    ]
  }

  getCurrentQuestion = () => {
    const { questionIndex } = this.state
    const { questions } = this.props

    if (!questions[questionIndex]) {
      console.warn("Trying to access question, that doesn't exist")
      return undefined
    } else {
      return questions[questionIndex]
    }
  }

  answerSelected = (answer) => () => {
    this.setState((state) => {
      const id = answer.id
      if (state.selectedIds.includes(id)) {
        state.selectedIds = _.pull(state.selectedIds, id)
      } else if (this.canChooseAnswer(state)) {
        state.selectedIds.push(id)
      }

      return {
        selectedIds: state.selectedIds,
      }
    })
  }

  canChooseAnswer = (state = this.state) => {
    const answersLimit = _.defaultTo(this.props.parameters["answersLimit"], DEFAULT_ANSWERS_LIMIT)
    return state.selectedIds.length < answersLimit
  }

  answersChosen = (send = false) => {
    const { selectedIds } = this.state
    const question = this.getCurrentQuestion()
    for (const answer of question.answers) {
      if (selectedIds.includes(answer.id)) {
        super._answerChosen(answer)
      }
    }

    let nextState, callback
    if (send) {
      nextState = STATES.ENTERING_EMAIL
    } else {
      nextState = STATES.FINISHING
      callback = this.onFinish
    }

    this.setCurrentStateSequence([STATES.HIDING_ANSWERS, nextState], ANIMATION_DURATION, callback)
  }

  renderEnteringEmail = () => {
    const { t } = this.props

    return [
      <div key="email-info" className={classNames("email-info")}>
        <Card className="email-form" color={Card.COLORS.MAIN}>
          <div
            className={classNames("message", this.state.message.type, {
              visible: this.state.message.content,
            })}
          >
            <p>{this.state.message.content}</p>
          </div>
          <label htmlFor="email">{t("choices.enter_email")}</label>
          <input
            name="email"
            type="email"
            value={this.state.form.email}
            onChange={this.emailFormChanged}
          />
          <p>{t("choices.email_info")}</p>
        </Card>
      </div>,
      <AnimatedElement
        key="send-button"
        visible={this.inStates([STATES.ENTERING_EMAIL, STATES.SENDING, STATES.SENT])}
      >
        <Button
          className={"send-button"}
          disabled={this.inStates([STATES.SENDING, STATES.SENT])}
          onClick={this.sendAnswers}
          big
        >
          {!this.inStates([STATES.SENDING, STATES.SENT]) && t("send")}
          {this.inStates([STATES.SENDING, STATES.SENT]) && (
            <span>
              <FontAwesomeIcon className="icon" icon={faSpinner} spin /> {t("choices.sending")}
            </span>
          )}
        </Button>
      </AnimatedElement>,
    ]
  }

  emailFormChanged = (event) => {
    const name = event.target.name
    const value = event.target.value

    this.setState((state) => {
      state.form[name] = value

      return {
        form: state.form,
      }
    })
  }

  sendAnswers = () => {
    const errors = validate(this.state.form, ChoicesExercise.fieldConstraints)

    if (errors) {
      this.setState((state) => {
        let errorString = ""
        for (let errorList of Object.values(errors)) {
          errorString += errorList.join(" ")
        }

        state.message.content = errorString

        return {
          formError: errorString,
        }
      })
    } else {
      this.setCurrentState(STATES.SENDING, () => {
        this.setState(
          {
            message: {
              content: "",
              type: "error",
            },
          },
          this.sendAnswersToServer
        )
      })
    }
  }

  sendAnswersToServer = () => {
    const { t } = this.props

    const chosen = []
    const left = []
    const {
      selectedIds,
      form: { email },
    } = this.state
    const question = this.getCurrentQuestion()
    for (const answer of question.answers) {
      if (selectedIds.includes(answer.id)) {
        chosen.push(answer.content)
      } else {
        left.push(answer.content)
      }
    }

    const success = () => {
      this.setCurrentState(STATES.SENT, () => {
        this.setState(
          {
            message: {
              content: t("choices.message_sent"),
              type: "success",
            },
          },
          () => {
            this.delayedSetCurrentState(
              STATES.FINISHING,
              ANIMATION_DURATION,
              this.onFinish,
              ANIMATION_DURATION
            )
          }
        )
      })
    }

    axios({
      method: "POST",
      url: SERVER_ADDRESS + (this.type ? `choices/send/${this.type}` : "choices/send"),
      data: {
        email,
        chosen,
        left,
        language: CONFIG.LANGUAGE,
      },
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(() => {
        success()
      })
      .catch(() => {
        this.failCount += 1
        if (this.failCount > MAX_FAILURES) {
          success()
        } else {
          this.setCurrentState(STATES.ENTERING_EMAIL, () => {
            this.setState((state) => {
              let errorString = t("choices.error")

              state.message.content = errorString

              return {
                formError: errorString,
              }
            })
          })
        }
      })
  }

  onFinish = () => {
    this.finish(false, {
      chosenAnswerIds: this.state.selectedIds,
    })
  }
}

export default withTranslation(["common"])(ChoicesExercise)
